import { Component, OnInit } from '@angular/core';
import { FilesFacadeService } from './services/files-facade.service';
import { NodeModel } from './models/node.model';
import { Subject, Subscription } from 'rxjs';
import { DisplayErrorService } from '@app/core/services/error/display-error.service';
import { StateService } from '@app/private/services/state.service';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { Desk } from '@app/shared/models/user.model';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MessageType } from '@app/core/enum/message-type.enum';

@Component({
  selector: 'app-workingspace',
  templateUrl: './workingspace.component.html',
  styleUrls: ['./workingspace.component.css'],
})
export class WorkingspaceComponent implements OnInit {
  currentDeskTitle: null;
  roleUser = "ADMIN"
  allowedFunctionalities = [] 
  dataContent;



  currentNode: NodeModel;               /** To retrieve the current Node */
  wholeTree: NodeModel;         /** To retrieve the Whole tree */
  currentDesk: Desk = null;          /** To retrieve the user's current desk */
  breadcrumb = null;            /** To retrieve the user's breadcrumb*/
  subscription: Subscription;   /** To know each time the user has changed company or desk, it will be very useful 
                                 * to unsubscribe of the service when the component is destroyed.*/
  destroyed = new Subject<any>();
  initialClickedFolderNoLongerExists;
  displayLoader: boolean = false;

  currentFolderID;

  bsModalRef: BsModalRef;
  data = null;

  activate=true
  userRoles:["owner","guest"] 
  functionalities = {
    folder: [
             {title:"new", icon: "fa fa-plus-square fa-sm", subFunctionality: [ {title: "folder", icon: "fa fa-folder fa-sm" }, {title: "fsdfsdf", icon: "fa fa-copy fa-sm" } ]}, 
             {title:"upload", icon: "fa fa-upload fa-sm"}, {title:"rename", icon: "fa fa-edit fa-sm"}, {title:"copy", icon: "fa fa-copy fa-sm"}, {title:"paste", icon: "fa fa-paste fa-sm"}, {title:"move", icon: "fa fa-arrows fa-sm"}, {title:"delete", icon: "fa fa-trash-o fa-sm"}, {title:"properties", icon: "fa fa-info-circle fa-sm"},
            ], 
    flateFile: [
                {title:"doawnload", icon: "fa fa-download fa-sm"}, {title:"rename", icon: "fa fa-edit fa-sm"}, {title:"copy", icon: "fa fa-copy fa-sm"}, {title:"paste", icon: "fa fa-paste fa-sm"}, {title:"move", icon: "fa fa-arrows fa-sm"}, {title:"properties", icon: "fa fa-info-circle fa-sm"}
              ]
  }

  constructor(
    private filesFacadeService: FilesFacadeService,
    private displayErrorService: DisplayErrorService,
    private stateService: StateService,
    private router: Router,
    private modalService: BsModalService
  ) { }

  ngOnInit(): void {
    let savedCurrentDeskId = null;
    if (this.breadcrumb == null) {
      this.retrieveSavedBreadcrumb();
      
    }
    if (this.wholeTree == null) {
      this.retrieveSavedWholeTree();
    }

    this.subscription = this.stateService.selectedCurrentDeskEvent.subscribe(
      (data) => {
        if (data) {
          this.currentDesk = data.currentDesk;

          savedCurrentDeskId = localStorage.getItem("app/modules/private/modules/workingspace/workingspaceComponent.currentDeskId");
          //if ( this.currentDesk?.id !== savedCurrentDeskId) {
          if (savedCurrentDeskId == null || this.currentDesk?.id !== savedCurrentDeskId) {
            this.displayLoader = true;
            localStorage.setItem("app/modules/private/modules/workingspace/workingspaceComponent.currentDeskId", this.currentDesk.id);
            localStorage.setItem("app/modules/private/modules/workingspace/workingspaceComponent.currentDeskTitle", this.currentDesk.title);
            this.initWholeTree();
          }
        }
      }
    );

    if (this.currentDesk && (this.currentDesk.id === savedCurrentDeskId)) {
      this.displayLoader = true;
      try {
        this.updatePartTree(this.breadcrumb.slice(-1)[0].id); // refresh the content since the received desk is the currentDesk
      }
      catch {
        this.initWholeTree(); // if the breadcrumb is corrupted (syntax error) "this.breadcrumb.slice(-1)[0].id" will lead us to this catch block
      }
    }

    this.router.events.pipe(filter((event: RouterEvent) => event instanceof NavigationEnd), takeUntil(this.destroyed)).subscribe(() => {
      this.displayLoader = true;
       try{
          this.updatePartTree(this.breadcrumb.slice(-1)[0].id); // refresh the content since the received desk is the currentDesk
        }
        catch {
          this.initWholeTree(); // if the breadcrumb is corrupted (syntax error) "this.breadcrumb.slice(-1)[0].id" will lead us to this catch block
        }
    });
    
  }

   retrieveSavedBreadcrumb(){ 
      this.breadcrumb = JSON.parse(localStorage.getItem("app/modules/private/modules/workingspace/workingspaceComponent.breadcrumb"));
  }

   retrieveSavedWholeTree(){
    this.wholeTree = JSON.parse(localStorage.getItem("app/modules/private/modules/workingspace/workingspaceComponent.wholeTree")); 
  }

  /***** these isCorruptedBreadcrumb() and isCorruptedWholeTree() functions are not used 
  isCorruptedBreadcrumb(breadcrumb){
     let lastElement = breadcrumb.slice(-1)[0];
      if(lastElement.hasOwnProperty('id') && lastElement.hasOwnProperty('name') && lastElement.hasOwnProperty('parentID')){
        return false;
      }
      else{
        true;
      }
   }
 
   isCorruptedWholeTree(wholeTree) {
     let corruptNode = false;
     let queue = [wholeTree];
     while(!corruptNode && queue?.length){
       let node = queue.shift();
       if(!node.hasOwnProperty('id') || !node.hasOwnProperty('name') || !node.hasOwnProperty('folder') || !node.hasOwnProperty('modifiedDate') 
         || !node.hasOwnProperty('isExpand') || !node.hasOwnProperty('parentID') || !node.hasOwnProperty('subFiles')){
           corruptNode = true;
           return true;
       }else{
         node.subFiles?.forEach(child => {
           queue.push(child);
         });
       }
     }
       return false;
   }*/


  onClickFolderEvent(nodeID) {
    this.displayLoader = true;
    this.updatePartTree(nodeID);
  }

  retrieveNode(wholeTree, targetNodeID) {
    
    let currentChild;
    let result;
    if (targetNodeID == wholeTree?.id) {
      return wholeTree;
    } else {
      for (let i = 0; i < wholeTree?.subFiles?.length; i += 1) {
        currentChild = wholeTree?.subFiles[i];
        result = this.retrieveNode(currentChild, targetNodeID);
        if (result !== null) {
          return result;
        }
      }
    }
    return null;
  }

  computeBreadcrumb(currentNode) {
    this.breadcrumb = []; let i=1;
    while (currentNode != null &&  currentNode.id != currentNode.parentID) {
      this.breadcrumb.unshift({ id: currentNode.id, name: currentNode.name, parentID: currentNode.parentID });
      try {
        currentNode = this.retrieveNode(this.wholeTree, currentNode.parentID);
        currentNode.isExpand = true;
      }catch {
        return this.initWholeTree();
      }
    }

    if (currentNode == null) {
      this.initWholeTree();
    }
    else {
      this.breadcrumb.unshift({ id: currentNode.id, name: currentNode.name, parentID: currentNode.parentID });
      localStorage.setItem("app/modules/private/modules/workingspace/workingspaceComponent.breadcrumb", JSON.stringify(this.breadcrumb));
    }

    this.dataContent = this.currentNode
  }

  updatePartTree(nodeID) {
    if (!nodeID) return this.initWholeTree();
    try {
      this.currentNode = this.retrieveNode(this.wholeTree, nodeID);
    } catch (error) {
      return this.initWholeTree();
    }

    if (!this.currentNode) return this.initWholeTree();

    this.filesFacadeService.getChildrenOfFolder(this.currentDesk.company.id, this.currentDesk.id, nodeID, true).subscribe(
      (reponse) => {
        let subFilesSub = [];
        for (const key in reponse['subFiles']) {
          subFilesSub.push({
            id: reponse['subFiles'][key]['id'], name: reponse['subFiles'][key]['name'], folder: reponse['subFiles'][key]['folder'],
            modifiedDate: reponse['subFiles'][key]['modifiedDate'], isExpand: false, parentID: nodeID, subFiles: reponse['subFiles'][key]['subFiles']
          });
        }
        this.currentNode.name = reponse['name'];
        this.currentNode.folder = reponse['folder'];
        this.currentNode.modifiedDate = reponse['modifiedDate'];
        this.currentNode.isExpand = true;
        this.currentNode.subFiles = subFilesSub;
        this.closingOperations(this.currentNode);
        
      },
      (error) => {
        if (error.messageID == "KOOSSERYDESKWORKINGSPACESVCO-023" || error.messageID == "KOOSSERYDESKWORKINGSPACESVCO-009") {
          this.initialClickedFolderNoLongerExists = true;
          this.updatePartTree(this.currentNode.parentID);
        }
        else {
          this.displayLoader = false;
            this.displayErrorService.openModalWithComponent(error);
        }
      }
    );

  }

  initWholeTree() {
    this.filesFacadeService.getChildrenOfRootFolder(this.currentDesk.company.id, this.currentDesk.id, true).subscribe(
      (reponse) => {
        let subFiles = [];
        let child;
        for (const key in reponse['subFiles']) {
          child = {
            id: reponse['subFiles'][key]['id'],
            name: reponse['subFiles'][key]['name'],
            folder: reponse['subFiles'][key]['folder'],
            modifiedDate: reponse['subFiles'][key]['modifiedDate'],
            isExpand: false,
            parentID: reponse['id'],
            subFiles: reponse['subFiles'][key]['subFiles'],
          };
          subFiles.push(child);
        }

        const model: NodeModel = {
          id: reponse['id'],
          name: reponse['name'],
          folder: reponse['folder'],
          modifiedDate: reponse['modifiedDate'],
          isExpand: true,
          parentID: reponse['id'],
          subFiles: subFiles
        };
        this.currentNode = model;
        this.wholeTree = model;

        this.closingOperations(model);
      },
      (error) => {
        this.displayLoader = false;
          this.displayErrorService.openModalWithComponent(error);
      }
    );
  }

  closingOperations(currentNode) {
    this.displayLoader = false;
    localStorage.setItem("app/modules/private/modules/workingspace/workingspaceComponent.wholeTree", JSON.stringify(this.wholeTree));
    this.computeBreadcrumb(currentNode);

    if (this.initialClickedFolderNoLongerExists === true) {
      this.displayErrorService.openModalWithComponent({message:"Your workingspace has been updated!", messageType:MessageType.WARNING});
      this.initialClickedFolderNoLongerExists = false;
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.destroyed.next();
    this.destroyed.complete();
  }

  onFunctionnalityIdFolderEvent(data){
    this.currentFolderID = data.idOfFolder
    if(data.nameOfFonctionnality=="upload"){
  // this.bsModalRef = this.modalService.show(UploadComponent);
      /*this.bsModalRef.content.selectedFilesEvent.subscribe(data => {
        console.log('Child component\'s event was triggered', data);
        console.log('---------------Child component\'s event was triggered--------------------------');
        console.log(data);
        alert(data.nom);
      });*/
    }
  }
}