export class Company{
	id: string;
	name: string;
	roles : string[] // add
}

export class Desk{
          /**
	 * The desk ID
	 */
	id: string;
	/**
	 * The parent desk ID
	 */
	parentID?: string;
	/**
	 * The desk title
	 */
	title: string;

	/**
	 * The company to which the desk belong
	 */
	company?: Company;
	

    /**
	 * The sub-desks list of an desk
	 */
	subDesks?: Desk[];
}


export class UserModel{
	uId?: string;
    firstName: string;
	lastName:string;
	company?: Company;
    phoneNumber?:string;
    username?:string;
    email:string;
    password?:string;
	desks: Desk[];
	roles = new Map;  // add
}
