import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable()
export class StateService {
 
  private _logoutEvent: Subject<boolean> = new Subject<boolean>(); 
  private _updateProfileEvent: Subject<any> = new Subject<any>();
  private _updateListOfDeskEvent: Subject<any> = new Subject<any>(); 
  private _selectedCurrentDeskEvent: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  private _email: string;
  private _company: any;
  private _roles: any;
 public val= true;

  constructor() { }

  get logoutEvent(){
    return this._logoutEvent;
  }

  get updateProfileEvent(){
    return this._updateProfileEvent;
  }

  get updateListOfDeskEvent(){
    return this._updateListOfDeskEvent;
  }

  get selectedCurrentDeskEvent(){
    return this._selectedCurrentDeskEvent.asObservable();
  }
 

  emitSelectedCurrentDeskEvent(data){
    this._selectedCurrentDeskEvent.next(data);
  }

  get email(){
    return this._email;
  }

  set email(email){
    this._email = email;
  }

  get company(){
    return this._company;
  }

  get roles(){
    return this._roles;
  }

  set roles(roles){
    this._roles = roles;
  }

  set company(comp){
    this._company = comp;
  }

  emitLogoutEvent(){
    this._logoutEvent.next(true);
  }

  emitUpdateProfileEvent(data){
    this._updateProfileEvent.next(data);
  }
  
  emitUpdateListOfDeskEvent(data){
    this._updateListOfDeskEvent.next(data);
  }
  
}
