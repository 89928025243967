import {
  AfterViewInit,
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { Router } from "@angular/router";
import { HttpConfigService } from "@app/core/services/http/http-config.service";
import { VoiceService } from "@app/core/services/voice/voice.service";
import { StateService } from "@app/public/services/state.service";
import { Platform } from "@ionic/angular";
import { CarouselConfig } from "ngx-bootstrap/carousel";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
  providers: [
    {
      provide: CarouselConfig,
      useValue: { interval: 1500, noPause: true, showIndicators: true },
    },
  ],
})
export class HomeComponent
  implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  btnDimension = [{ width: "230px" }, { width: "190px" }];
  selectedCrop = null;
  isDisabled = true;

  crops = null;

  indexID = 0;
  currentNode: ChildNode = null;
  slideIndex = 1;
  timeoutId;
  currentTime = 0;
  currentVidio: any = document.getElementById("video" + this.indexID);
  width = 55;

  constructor(
    private router: Router,
    public platform: Platform,
    private stateService: StateService,
    private voiceService: VoiceService,
    private httpconfig: HttpConfigService
  ) {}

  ngOnInit(): void {
    this.httpconfig.ConfigReady.subscribe((val) => {
      if (val && this.platform.is("hybrid")) {
        this.initCrop(true);
      }
    });
  }

  downloadApk() {
    this.router.navigate(["/public/download"]);
  }

  ngAfterViewInit() {
    this.currentVidio = document.getElementById("video" + this.indexID);
    this.currentVidio?.load();
    this.currentVidio.muted = true;
    this.currentVidio?.play();
  }

  onDiagnoseCrop($event) {
    this.redirectToDiagnoctic($event);
  }

  onGrowCrop($event) {
    this.redirectToDiagnoctic($event);
  }

  videoEnd(id) {
    setTimeout(() => {
      this.videoNext(id);
    }, 1000);
  }

  videoNext(id, i = 0) {
    let idx = id.charAt(id.length - 1);

    i ? (this.indexID = (idx + 2) % 3) : (this.indexID = (idx + 1) % 3);

    setTimeout(() => {
      this.currentVidio = document.getElementById("video" + this.indexID);
      this.currentVidio.load();
      this.currentVidio.muted = true;
      this.currentVidio.play();
    }, 2000);
  }

  redirectToDiagnoctic(data: any) {
    this.voiceService.speak("");
    this.router.navigate(["/public/diagnostic"], { state: { data: data } });
  }

  onSend(crop) {
    this.redirectToDiagnoctic(crop);
  }

  onPictureTaken(picture) {
    this.redirectToDiagnoctic(picture);
  }

  onfileUploaded(file) {
    this.redirectToDiagnoctic(file);
  }

  setActiveLang(lang: string) {}

  initCrop(displayError) {
    this.crops = this.stateService.getCrops(displayError);
    try {
      if (localStorage.getItem("selectedCrop") != null) {
        this.selectedCrop = JSON.parse(localStorage.getItem("selectedCrop"));
        this.selectCrop(this.selectedCrop);
      }
    } catch (error) {
      this.selectedCrop = null;
    }
  }

  onRetreive(displayError) {
    this.initCrop(displayError);
  }

  selectCrop(crop) {
    this.selectedCrop = crop;
    window.scroll({
      top: 0,
      left: 100,
      behavior: "smooth",
    });
    localStorage.setItem("selectedCrop", JSON.stringify(crop));

    setTimeout(() => {
      let radioButtom = document.getElementById(crop.id) as HTMLInputElement;
      radioButtom.checked = true;
    }, 1000);
  }

  onUpdateSelectedCrop(event) {
    this.selectCrop(event);
  }

  ngOnChanges() {}

  ngOnDestroy() {}
}
