import { HttpClient, HttpEvent } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { HttpConfigService } from "@app/core/services/http/http-config.service";
import { concat, Observable } from "rxjs";

@Injectable()
export class ToolsFacadeService {
  servicePath = "koosserydesk.ai.python/service/tool/";

  constructor(
    private httpConfig: HttpConfigService,
    private http: HttpClient
  ) {}

  retreiveTools() {
    return this.http.get<any>(
      this.httpConfig?.aiServer + "/" + this.servicePath + "findAll"
    );
  }
}
