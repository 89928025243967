import { Injectable } from '@angular/core';
import { SecurityService } from '@app/private/services/security.service';
import { StateService as PrivateStateService} from '@app/private/services/state.service';
import { Subscription } from 'rxjs';

@Injectable()
  export class StateService {
   public allowedFunctionalities : any[] = []
   functionalitiesToModified: any[] = []
   userRole : string;
   subscription: Subscription;
   currentDeskPath;
   rootName;
   
   constructor(private securityService: SecurityService, private privateStateService: PrivateStateService){
    this.subscription = this.privateStateService.selectedCurrentDeskEvent.subscribe(
      (data) => {
        if(data){
          /*this.company = data.currentDesk.company.id
          localStorage.setItem("company", this.company);*/
          this.userRole = this.privateStateService.roles[data.currentDesk.id];
          this.currentDeskPath = data.currentDeskPath;
          this.rootName = data.currentDesk.title

          //this.userRole = "OWNER";
      }
    }
    );
  }

  //manage case 1  
rolesConfig(breadcrumb, action ){	
  let roles =  this.securityService.rolesConfig

  for (const role in roles) {
    if (role.toUpperCase() === this.userRole.toUpperCase()) {
      this.allowedFunctionalities = roles[this.userRole.toUpperCase()];

      console.log("------------------------------------case 1-------------------------------------------------")
      console.log("fonctionnalities ==>  " + this.allowedFunctionalities)
      console.log("Current Role     ==>  " + this.userRole) 
       console.log("breadcrumb   ==>  " + breadcrumb) 
      console.log("------------------------------------case 1-------------------------------------------------")    
      
      if(breadcrumb){
         return this.specificDesksConfig(breadcrumb, action)
      }
      else{
        return this.checkRoot(action)
      }
      
    }  
  }
  console.log("------------------------------------case 1-----------------------------------------------------------------------------------")
  console.log("Error ==>" + " " +" The user role has no functionality or the user's role is not into account in the configuration file")
  console.log("------------------------------------case 1------------------------------------------------------------------------------------")
    return this.allowedFunctionalities = []                              	                                 							
}


 //manage case 2
 specificDesksConfig(breadcrumb, action){
  let candidateAllowedFunctionalities : any[] = []
  let roles;
 
  for (const candidatDesk in this.securityService.specificDesksConfig) {
    if (candidatDesk.toLowerCase() === this.currentDeskPath.toLowerCase()) {
        roles = this.securityService.specificDesksConfig[candidatDesk];
        
        for (const role in roles) {
          if (role.toUpperCase() === this.userRole.toUpperCase()) {
            for (const functionality of roles[this.userRole.toUpperCase()]){
              if(this.allowedFunctionalities.includes(functionality)){
                candidateAllowedFunctionalities.push(functionality)
              }
              else{
                this.functionalitiesToModified.push({
                   nameOfFonctionality: functionality, 
                   errorMessage: "This functionality of case 2 is not present among the global functionalities of the case 1"
                  })
                console.log(" Error in the case -2- " + JSON.stringify(this.functionalitiesToModified))
              }
  
            }

              console.log("------------------------------------case 2-------------------------------------------------")
              console.log("fonctionnalities of the case 2 ==>  " + roles[this.userRole.toUpperCase()])
              console.log("fonctionnalities of the case 1 ==>  " + this.allowedFunctionalities)
              console.log("case 2 **intersection** case 1  ==>  " + candidateAllowedFunctionalities)
              console.log("Current Role     ==>  " + this.userRole)  
              console.log("------------------------------------case 2-------------------------------------------------")

            if(candidateAllowedFunctionalities){
              this.allowedFunctionalities = candidateAllowedFunctionalities
            }  

            return this.specificFoldersConfig(breadcrumb, action)
          }  
        }
    }
  }

      return this.specificFoldersConfig(breadcrumb, action)
    //return this.checkAccess(this.allowedFunctionalities, action)
}

//manage case 3
specificFoldersConfig(breadcrumb, action){
  let candidateAllowedFunctionalities : any[] = []
  let roles;

  for (const candidatBreadcrumb in this.securityService.specificFoldersConfig) {
    if (candidatBreadcrumb.toLowerCase() === breadcrumb.toLowerCase()) {
        roles = this.securityService.specificFoldersConfig[breadcrumb];
        for (const role in roles) {
          if (role.toUpperCase() === this.userRole.toUpperCase()) {
            for (const functionality of roles[this.userRole.toUpperCase()]){
              if(this.allowedFunctionalities.includes(functionality)){
                candidateAllowedFunctionalities.push(functionality)
              }
              else{
                this.functionalitiesToModified.push({
                     nameOfFonctionality: functionality, 
                     errorMessage: "This functionality of case 3 is not present among the global functionalities"
                })
                console.log(" Error in the case -3-  " + JSON.stringify(this.functionalitiesToModified))
              }
  
            }

            console.log("------------------------------------case 3-------------------------------------------------")
            console.log("fonctionnalities of the case 3 ==>  " + roles[this.userRole.toUpperCase()])
            console.log("global functionalities ==>  " + this.allowedFunctionalities)
            console.log("case 3 **intersection** global functionalities ==>  " + candidateAllowedFunctionalities)
            console.log("Current Role     ==>  " + this.userRole)  
            console.log("------------------------------------case 3-------------------------------------------------")
            if(candidateAllowedFunctionalities){
              this.allowedFunctionalities = candidateAllowedFunctionalities
            }
            return this.specificDeskFoldersConfig(breadcrumb, action)
          }  
        }

    }
  }
  //return this.checkAccess(this.allowedFunctionalities, action)
  return this.specificDeskFoldersConfig(breadcrumb, action)
}



specificDeskFoldersConfig(breadcrumb, action){        //case 4
  let candidateAllowedFunctionalities : any[] = []
  let roles;
  let files;

  for (const desk in this.securityService.specificDeskFoldersConfig) {
    if (desk == this.currentDeskPath) { 
        files = this.securityService.specificDeskFoldersConfig[desk];
        for (const bread in files) {
            if (bread.toLowerCase() === breadcrumb.toLowerCase()) { 
              roles = files[bread];
              for (const role in roles) {
                if (role.toUpperCase() === this.userRole.toUpperCase()) {
                  for (const funtionality of roles[this.userRole.toUpperCase()]){
                    if(this.allowedFunctionalities.includes(funtionality)){
                      candidateAllowedFunctionalities.push(funtionality)
                    }
                    else{
                      this.functionalitiesToModified.push({
                        nameOfFonctionality: funtionality, 
                        errorMessage: "This functionality of case 4 is not present among the global functionalities"
                      })
                        console.log("  Error in the case -4-  " + JSON.stringify(this.functionalitiesToModified))
                    }
        
                  }
                  if(candidateAllowedFunctionalities?.length<=0){
                    this.allowedFunctionalities = candidateAllowedFunctionalities
                  }
                    console.log("------------------------------------case 4-------------------------------------------------")
                    console.log("fonctionnalities of the case 4 ==>  " + roles[this.userRole.toUpperCase()])
                    console.log("global functionalities ==>  " + this.allowedFunctionalities)
                    console.log("case 4 **intersection** global functionalities ==>  " + candidateAllowedFunctionalities)
                    console.log("Current Role     ==>  " + this.userRole)  
                    console.log("------------------------------------case 4-------------------------------------------------")
                    
                    return this.checkAccess(this.allowedFunctionalities, action) 
      
                }  
              }  
            }
          }
        }
        else{
          return this.checkAccess(this.allowedFunctionalities, action)
        }
 }
}

 //manage root
 checkRoot(action){
  let roles =  this.securityService.rootConfig
  let candidateAllowedFunctionalities : any[] = []

  for (const role in roles) {
    if (role.toUpperCase() === this.userRole.toUpperCase()) {
      for (const funtionality of roles[this.userRole.toUpperCase()]){
        if(this.allowedFunctionalities.includes(funtionality)){
          candidateAllowedFunctionalities.push(funtionality)
        }
        else{
          this.functionalitiesToModified.push({
            nameOfFonctionality: funtionality, 
            errorMessage: "This functionality of case Root is not present among the global functionalities of case 1"
          })
            console.log("  Error in the case -Root-  " + JSON.stringify(this.functionalitiesToModified))
        }

      }

      console.log("------------------------------------case Root-------------------------------------------------")
      console.log("fonctionnalities of the case Root ==>  " + roles[this.userRole.toUpperCase()])
      console.log("fonctionnalities of the case 1 ==>  " + this.allowedFunctionalities)
      console.log("case Root **intersection** case 1 ==>  " + candidateAllowedFunctionalities)
      console.log("Current Role     ==>  " + this.userRole)  
      console.log("------------------------------------case Root-------------------------------------------------")


      if(candidateAllowedFunctionalities){
        this.allowedFunctionalities = candidateAllowedFunctionalities
      }
       
       
        return this.checkAccess(this.allowedFunctionalities, action) 

    }  
  }


} 

checkAccess(allowedRoles, action){
  for (const role in allowedRoles) {
    if (role.toUpperCase() === this.userRole.toUpperCase()) {
      this.allowedFunctionalities = allowedRoles[this.userRole.toUpperCase()];
          if(this.allowedFunctionalities.includes(action))
            return true;
          else
            return false  
    }  
  }
  return false
}





/*
checkSpecificDeskFoldersConfig(breadcrumb, action){        //case 4
  let files
  let roles  
  this.allowedFunctionalities = []
    for (const desk in this.securityService.specificDeskFoldersConfig) {
        if (desk == this.currentDeskPath) { 
            files = this.securityService.specificDeskFoldersConfig[desk];
            for (const bread in files) {
                if (bread.toLowerCase() === breadcrumb.toLowerCase()) { 
                  roles = files[bread];
                  return this.checkAccess(roles, action)                
                }
              }
            }
     }

     return this.checkSpecificFoldersConfig(breadcrumb, action)
  }


checkAccess(allowedRoles, action){
  for (const role in allowedRoles) {
    if (role.toUpperCase() === this.userRole.toUpperCase()) {
      this.allowedFunctionalities = allowedRoles[this.userRole.toUpperCase()];
          if(this.allowedFunctionalities.includes(action))
            return true;
          else
            return false  
    }  
  }
  return false
}

  checkSpecificFoldersConfig(breadcrumb, action){                  // case 3
    let roles;
    for (const candidatBreadcrumb in this.securityService.specificFoldersConfig) {
      if (candidatBreadcrumb.toLowerCase() === breadcrumb.toLowerCase()) {
          roles = this.securityService.specificFoldersConfig[breadcrumb];
         ***console.log("------------------------------------case 3-------------------------------------------------")
          console.log(roles)
          console.log(this.userRole)
          console.log("------------------------------------case 3-------------------------------------------------")***
          return this.checkAccess(roles, action)   
      }
    }
    return this.checkSpecificDesksConfig(action)
  }

  checkSpecificDesksConfig(action){                                         // case 2
 
    let roles;
    for (const candidatDesk in this.securityService.specificDesksConfig) {
      if (candidatDesk.toLowerCase() === this.currentDeskPath.toLowerCase()) {
          roles = this.securityService.specificDesksConfig[candidatDesk];
         ****console.log("------------------------------------case 2-------------------------------------------------")
          console.log(roles)
          console.log(this.userRole)
          console.log("------------------------------------case 2-------------------------------------------------")**
          return this.checkAccess(roles, action)   
      }
    }
    return this.checkRolesConfig(action);
  }

  checkRolesConfig(action){	                                             // case 1		
    ***console.log("------------------------------------case 1-------------------------------------------------")
    console.log(this.securityService.rolesConfig)
    console.log(this.userRole)
    console.log("------------------------------------case 1-------------------------------------------------")****                                         								
    return this.checkAccess(this.securityService.rolesConfig, action);
  }



*/











}  
