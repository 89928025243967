import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { StateService as PrivateStateService} from '@app/private/services/state.service';
import { StateService } from '../../services/state.service';


@Component({
  selector: 'app-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.css'],
})
export class TreeComponent implements OnInit {
  @Input() treeData
  @Output() clickFoldersEvent = new EventEmitter<any>()
  @Input() folderClickedID : string
  @Input() listOfFunctionalitiesOfFolders

  @Output() functionnalityIdFolderEvent = new EventEmitter<any>()
  folderCurrentID = null;
  curentBreadcrumb = null;

  constructor(private privateStateService: PrivateStateService, private stateService: StateService) {}

  ngOnInit(): void {}


  emitClickFoldersEvent(currentNode){
    if(currentNode.folder){
      this.clickFoldersEvent.emit(currentNode.id)
    }
  }

  onClickIcon(currentNode){
    if(!currentNode.isExpand){
      this.emitClickFoldersEvent(currentNode)
    }
    else{
      currentNode.isExpand = false
    }
  }  

		isAllowed(action, breadcrumb2, last){
      let data = breadcrumb2.split("/");
      let breadcrumb = data.slice(1).join("/")
      
			if(breadcrumb == this.curentBreadcrumb){
        if(last==true)  this.curentBreadcrumb = ""  // Used to manage point 1.2 of the strategy (Point 5/ of the workingspace.2 strategy) 
        if(this.stateService.allowedFunctionalities.includes(action)){
          return true;
        }
          return false;
      }
        else{
					this.curentBreadcrumb=breadcrumb;
					return this.stateService.rolesConfig(breadcrumb, action);
				}
	}


}
