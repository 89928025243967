/* "Barrel" of Http Interceptors */
import { HTTP_INTERCEPTORS } from "@angular/common/http";

import { ErrorHandlerInterceptor } from "./error-handler.interceptor";
import { AuthenticatorInterceptor } from "./authenticator.interceptor";
import { UserLanguageInterceptor } from "./userLanguageInterceptor";

/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorHandlerInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthenticatorInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: UserLanguageInterceptor,
    multi: true,
  },
];
