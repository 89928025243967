import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { PermissionGuard } from '@app/core/guards/permission.guard';
import { Router } from '@angular/router';
import { SignInFacadeService } from '@app/public/modules/login/components/sign-in/services/sing-in-facade.service';
import { DisplayErrorService } from '@app/core/services/error/display-error.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { takeUntil } from 'rxjs/operators';
import { ForgotPasswordFacadeService } from './services/forgot-password-facade.service';
import { HttpConfigService } from '@app/core/services/http/http-config.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css'],
  providers: [ForgotPasswordFacadeService]
})
export class ForgotPasswordComponent implements OnInit {

  signinForm: FormGroup;
  destroy$: Subject<boolean> = new Subject<boolean>();
  displayLoader: boolean;
  error: boolean;
  showMessage: boolean;
  confirmationMessage: string;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private forgotPwdFacadeService: ForgotPasswordFacadeService,
    private displayErrorService: DisplayErrorService,
    public configService: HttpConfigService
  ) {}

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.signinForm = this.formBuilder.group({
      email: [
        '',
        [Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]
      ]
    });
  }

  onSubmit() {
    if (!this.signinForm.invalid) {
      const email = this.signinForm.get('email').value;
      this.displayLoader = true;
      const password = "";
      this.forgotPwdFacadeService
        .resetPassword(email)
        .subscribe(
          res => {
            this.confirmationMessage = "Password reset successfully! A new password was sent to "+email;
            this.showMessage = true;
            this.displayLoader=false;
            console.log("res")
            console.log(res)
          }, 
          error => {
            this.displayErrorService.openModalWithComponent(error);
            this.displayLoader = false;
          }
        );
    } else {
      this.error = true;
      return false;
    }
  }

  /**
   * When Angular destroys a component it calls the ngOnDestroy() lifecycle method which,
   * in our case, calls the next() method to emit a value. so RxJS completes all subscribed Observables.
   */
  ngOnDestroy() {
    this.destroy$.next(true);
    // Unsubscribe from the subject
    this.destroy$.unsubscribe();
  }

}
