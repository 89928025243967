import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PermissionGuard } from '@app/core/guards/permission.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'public',
    pathMatch: 'full'
  },
  {
    path: 'public', 
    loadChildren: () => import('./modules/public/public.module').then(m => m.PublicModule) 
  },
  {
    path: 'private',
    loadChildren: ()=>import('./modules/private/private.module').then(m => m.PrivateModule),
    canActivate: [PermissionGuard],
    data: {
      roles: [
        "ROLE_ADMIN",
        "ROLE_USER",
        "ROLE_MODERATOR"
      ]
    }
  },
  { 
    path: '**',
    redirectTo: 'public',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, onSameUrlNavigation: "reload", anchorScrolling: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
