import { AfterContentChecked, Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { HttpConfigService } from "@app/core/services/http/http-config.service";
import { Platform } from "@ionic/angular";
import { TranslocoService } from "@ngneat/transloco";

@Component({
  selector: "app-languages-form",
  templateUrl: "./languages-form.component.html",
  styleUrls: ["./languages-form.component.css"],
})
export class LanguagesFormComponent implements OnInit, AfterContentChecked {
  default = null;
  availableLanguages = null;
  selectedLanguage: string = null;
  searchLanguage: any;
  filteredListLanguage: any = [];
  @Input() position = { right: 0, top: 0 };
  toggleLanguage = false;

  constructor(
    private translocoloService: TranslocoService,
    private router: Router,
    private httpconfig: HttpConfigService,
    public platform: Platform
  ) {}

  ngOnInit(): void {
    let dataLang = null;
   let si= setInterval(() => {
        dataLang = JSON.parse(localStorage.getItem("defaultLang"));
      if(dataLang && dataLang!="undefined" && dataLang?.code!=undefined){
        this.default = dataLang.code;
        if (this.httpconfig.languages.length)
          this.selectedLanguage = dataLang.name;
        let idLang = dataLang.code.split("-")[0];
        this.availableLanguages = this.httpconfig.languages;
        this.filteredListLanguage = this.availableLanguages;
        this.translocoloService.setActiveLang(idLang);
        this.toggleLanguage = true;
        clearInterval(si);
      }
    }, 200);
  }

  ngAfterContentChecked() {}

  filterDropdown(e) {
    let searchString = e.toLowerCase();
    if (!searchString) {
      this.filteredListLanguage = this.availableLanguages.slice();
      return;
    } else {
      this.filteredListLanguage = this.availableLanguages.filter(
        (user) => user.name.toLowerCase().indexOf(searchString) > -1
      );
    }
  }

  selectLanguage(lang) {
    if (lang.name != this.selectedLanguage) {
      this.selectedLanguage = lang.name;
      this.default = lang.code;
      localStorage.setItem("defaultLang", JSON.stringify(lang));
      if (this.router.url.indexOf("/home") > -1) {
        location.reload();
      } else {
        window.location.href = "/public";
      }
    }
  }
}
