import { Directive, EventEmitter, HostBinding, HostListener, Output } from '@angular/core'; 
import { DomSanitizer } from '@angular/platform-browser';


@Directive({
  selector: '[appDragDrop]'
})
export class DragDropDirective {

  @Output() sendDroppedFileEvent : EventEmitter<any> = new EventEmitter();

  @HostBinding("style.border") private background = "none"; //"#eee"

  constructor(private sanitizer: DomSanitizer) { }

  @HostListener("dragover", ["$event"]) public onDragOver(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = "1px solid white !important";
    //this.background = "#999";
  }

  @HostListener("dragleave", ["$event"]) public onDragLeave(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    //this.background = "red";
    this.background = "none !important";
  } 

  @HostListener('drop', ['$event']) public onDrop(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    //this.background = '#eee';
    this.background = "none !important";
    this.sendDroppedFileEvent.emit(evt);

  }


}
