import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { CoreModule } from "./modules/core/core.module";
import { ModalModule } from "ngx-bootstrap/modal";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgxPermissionsModule } from "ngx-permissions";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CookieService } from "ngx-cookie-service";
import { CarouselModule } from "ngx-bootstrap/carousel";
import { GoogleChartsModule } from "angular-google-charts";
import { AppVersion } from "@ionic-native/app-version/ngx";
import { TranslocoRootModule } from "./transloco-root.module";
import { HttpClientModule } from "@angular/common/http";
import { TextToSpeech } from "@ionic-native/text-to-speech/ngx";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    GoogleChartsModule,
    ModalModule.forRoot(),
    CarouselModule.forRoot(),
    FontAwesomeModule,
    AppRoutingModule,
    CoreModule,
    BrowserAnimationsModule,
    BsDropdownModule.forRoot(),
    NgxPermissionsModule.forRoot(),
    TranslocoRootModule,
    HttpClientModule,
  ],
  providers: [CookieService, AppVersion, TextToSpeech],
  bootstrap: [AppComponent],
})
export class AppModule {}
