import { Injectable } from "@angular/core";
import { DisplayErrorService } from "@app/core/services/error/display-error.service";
import { BehaviorSubject, Subscription } from "rxjs";
import { PublicFacadeService } from "./state-facade.service";

@Injectable()
export class StateService {
  private crops: any = null;
  subscription = new Subscription();

  constructor(
    private publicFacadeService: PublicFacadeService,
    private displayErrorService: DisplayErrorService
  ) {}

  initCrops(displayError: boolean = true) {
    this.subscription = this.publicFacadeService?.retreiveCrops().subscribe(
      (response) => {
        return this.setCrops(response);
      },
      (error) => {
        if (displayError) {
          this.displayErrorService?.openModalWithComponent(error);
        }
        return null;
      }
    );
  }

  setCrops(crops) {
    this.crops = [];
    for (let key in crops) {
      this.crops.push({ id: key, name: crops[key] });
    }
    this.crops.sort((a, b) => (a.id < b.id ? -1 : 1));
    return this.crops;
  }

  getCrops(displayError = true) {
    if (this.crops == null) {
      return this.initCrops(displayError);
    }
    return this.crops;
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.subscription.unsubscribe();
  }
}
