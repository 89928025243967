import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { PublicComponent } from "./public.component";
import { HomeComponent } from "./components/home/home.component";
import { DownloadComponent } from "./components/download/download.component";

const routes: Routes = [
  {
    path: "",
    component: PublicComponent,
    children: [
      {
        path: "home",
        component: HomeComponent,
      },
      {
        path: "download",
        component: DownloadComponent,
      },
      {
        path: "diagnostic",
        loadChildren: () =>
          import("./modules/diagnostic/diagnostic.module").then(
            (m) => m.DiagnosticModule
          ),
      },
      {
        path: "privacypolicy",
        loadChildren: () =>
          import("./modules/policy/policy.module").then((m) => m.PolicyModule),
      },
      {
        path: "**",
        redirectTo: "home",
      },
    ],
  },
];

@NgModule({
  imports: [CommonModule, RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PublicRoutingModule {}
