import { Injectable, Inject } from '@angular/core'
import { HttpClient } from '@angular/common/http' 
import { Observable } from 'rxjs' 
import { Saver, SAVER } from '@app/public/saver.provider'
import { downloadModel } from '../models/model'
import { downloadHelper } from '../helpers/download.helper'

@Injectable({ providedIn: "root" })
export class DownloadService {
  constructor(private httpClient: HttpClient, @Inject(SAVER) private save: Saver) {} 

  download(url: string, filename?: string): Observable<downloadModel> {
    return this.httpClient.get(url, {
      reportProgress: true,
      observe: 'events',
      responseType: 'blob'
    }).pipe(downloadHelper(data => this.save(data, filename)))
  }
  
}