import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-display-loader',
  templateUrl: './display-loader.component.html',
  styleUrls: ['./display-loader.component.css']
})
export class DisplayLoaderComponent implements OnInit {

  @Input()
  widht: number =75;

  @Input()
  height: number = 75;
  
  @Output() cancelLoadingEvent = new EventEmitter();

  constructor() { }

  cancel(){
    this.cancelLoadingEvent.emit(true);
  }

  ngOnInit() {
  }

}
