import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { of, Subject } from 'rxjs';
import { PermissionGuard } from '@app/core/guards/permission.guard';
import { Router } from '@angular/router';
import { DisplayErrorService } from '@app/core/services/error/display-error.service';
import { SignUpFacadeService } from './services/sign-up-facade.service';

import { HttpConfigService } from '@app/core/services/http/http-config.service';
//import { UserModel } from '@app/shared/models/user.model';
import { UserModel, Desk } from '@app/shared/models/user.model';
import { NgWizardConfig, NgWizardModule, NgWizardService, StepChangedArgs, StepValidationArgs, STEP_STATE, THEME, TOOLBAR_BUTTON_POSITION } from 'ng-wizard';
import { layer } from '@fortawesome/fontawesome-svg-core';
import { MessageType } from '@app/core/enum/message-type.enum';

//import $ from 'jquery';


@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent implements OnInit {
  stepStates = {
    normal: STEP_STATE.normal,
    disabled: STEP_STATE.disabled,
    error: STEP_STATE.error,
    hidden: STEP_STATE.hidden
  };
 
  config: NgWizardConfig = {
    selected: 0,
    theme: THEME.arrows,
    toolbarSettings: {
      toolbarExtraButtons: [
        //{ text: 'Finish', class: 'btn btn-info', event: () => { alert("Finished!!!"); } }
      ],

      //toolbarButto Loading: TOOLBAR_BUTTON_POSITION.start,
      showNextButton: false,
      showPreviousButton: false
    }
  }


  /*config: NgWizardConfig = {
    selected: 0,
    theme: THEME.arrows,
    toolbarSettings: {
      toolbarExtraButtons: [
        { text: 'Finish', class: 'btn btn-info', event: () => { alert("Finished!!!"); } }
      ],
    }
  };*/
   
  
  userSubscriptionCode =  null
  signUpForm: FormGroup;
  codeSubscriptionForm: FormGroup; 
  destroy$: Subject<boolean> = new Subject<boolean>();
  displayLoader: boolean;
  error: boolean;
  user: UserModel;

  confirmationMessage = "Check your email box or spam . . . ";

  constructor(private ngWizardService: NgWizardService, private formBuilder: FormBuilder, private permGuard: PermissionGuard,
    private router: Router, private signUpFacade: SignUpFacadeService, private displayErrorService: DisplayErrorService,
    public configService: HttpConfigService) {
    this.user = new UserModel;
  }

  ngOnInit() {
    this.initForm();
  }


  initForm() {
    this.signUpForm = this.formBuilder.group({
      firstName: [
        '',
        [Validators.required, Validators.pattern(/^[^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"])*|[ ]+$/)]
      ],
      lastName: [
        '',
        [Validators.required, Validators.pattern(/^[^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"])*|[ ]+$/)]
      ],
      phoneNumber: [
        '',
        [Validators.pattern('[+0-9]*')]
      ],
      userName: [
        '',[Validators.pattern('[a-zA-Z0-9@.]*')]
      ],
      email: [
        '',
        [Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]
      ],
      password: ['', Validators.required],
      deskName: [
        '',
        [Validators.pattern('[a-zA-Z0-9 ]*')]
      ],
    });

    this.codeSubscriptionForm = this.formBuilder.group({
      codeSubscription: ['',[Validators.required]],
    });
  }

  onSubmit() {
    let deskTitle : string = null; 
    if (!this.signUpForm.invalid) {
      this.user.uId = this.signUpForm.get('userName').value?.trim();
      this.user.firstName = this.signUpForm.get('firstName').value;
      this.user.lastName = this.signUpForm.get('lastName').value;
      this.user.email = this.signUpForm.get('email').value;
      this.user.phoneNumber = this.signUpForm.get('phoneNumber').value;
      this.user.password = this.signUpForm.get('password').value;

      deskTitle = this.signUpForm.get('deskName').value ? this.signUpForm.get('deskName').value : "koosserydesk" ;
   

      const desks: Desk[] = [{
        id: deskTitle,
        title: deskTitle,
        //ownerID: this.user.email,
      }];
  
      
      this.user.desks = desks;
      this.user.company = this.configService.defaultCompany;
     // this.user.company.roles=["OWNER", "OCCUPANT", "GUEST", "MODERATOR"]
      this.displayLoader = true;

      //this.user.roles[deskTitle] = "owner"

      console.log("JSON.stringify(this.user) user data");
      console.log(JSON.stringify(this.user));
      console.log(this.user);
      this.getSubscriptionCode();

    } else {
      this.error = true;
      return false;
    }
  }

 
  getSubscriptionCode(){     
    this.signUpFacade.getSubscriptionCode(this.user.uId, this.user.email, this.user.phoneNumber)
    .subscribe(
       (response) => {
         this.displayLoader = false;
         if(this.userSubscriptionCode == null){
          this.ngWizardService.next()
         }
         this.userSubscriptionCode = response.body;
         
         //alert(response.body)
       },
       (error) => {
         this.displayErrorService.openModalWithComponent(error);
         this.displayLoader = false;
       }
    );
  }

  onSubmitSubscriptionCode(){
    let code;
    if (!this.codeSubscriptionForm.invalid) {
      code = this.codeSubscriptionForm.get('codeSubscription').value;
      if(code === this.userSubscriptionCode){
        this.signUpFacade
        .saveUser(this.user)
        .subscribe(
          res => {
            //** here we will retrive the user's roles to initialise ngx-permission 
            const roles =  ['ROLE_MODERATOR']; //[this.user.roles.get(this.user.desks[id])];
            this.permGuard.roles = roles;
            //** ==================== 

            const redirect = this.permGuard.redirectUrl
              ? this.permGuard.redirectUrl
              : '/private/';
            this.displayLoader = false;
  
            const user = { body: res };
            console.log(user);
            //console.log("--------------------JSON.stringify(res) signUp ---------------------");
            //console.log(JSON.stringify(res));
            
            this.router.navigate([redirect], {
              state: { data: user }
            });
          },
          error => {
            this.displayErrorService.openModalWithComponent(error);
            this.displayLoader = false;
          }
        );
      }else{
        this.displayErrorService.openModalWithComponent({message:"The confirmation code is not correct!", messageType:MessageType.WARNING});
            this.displayLoader = false;
      }
    } 
    else{
      this.error = true;
      return false;
    }
 }

 close(){
   return
 }


showPreviousStep(event?: Event) {
  this.ngWizardService.previous();
}

showNextStep(event?: Event) {
  this.ngWizardService.next();
}

resetWizard(event?: Event) {
  this.ngWizardService.reset();
}

setTheme(theme: THEME) {
  this.ngWizardService.theme(theme);
}

stepChanged(args: StepChangedArgs) {
  console.log(args.step);
}

isValidTypeBoolean: boolean = true;

isValidFunctionReturnsBoolean(args: StepValidationArgs) {
  return true;
}

isValidFunctionReturnsObservable(args: StepValidationArgs) {
  return of(true);
}

goToStep2($event){
  $event.preventDefault();
  this.onSubmit()  
}

}
