import { Injectable } from "@angular/core";
import { AppVersion } from "@ionic-native/app-version/ngx";
import { Market } from "@ionic-native/market";
import { Platform } from "@ionic/angular";
import { HttpConfigService } from "../http/http-config.service";
import {
  Filesystem,
  Directory,
  DownloadFileOptions,
} from "@capacitor/filesystem";
import { FileOpener } from "@awesome-cordova-plugins/file-opener/ngx";
import { BehaviorSubject, Observable } from "rxjs";
import { DisplayErrorService } from "../error/display-error.service";
import { Router } from "@angular/router";
import { MessageType } from "@app/core/enum/message-type.enum";

@Injectable({
  providedIn: "root",
})
export class UpdateAppService {
  private _percentage: BehaviorSubject<number> = new BehaviorSubject<number>(
    -1
  );
  private countCall = 0;
  constructor(
    public platform: Platform,
    private appVersion: AppVersion,
    private fileOpener: FileOpener,
    private httpConfig: HttpConfigService,
    private displayErrorService: DisplayErrorService,
    private router: Router
  ) {}

  get percentage(): Observable<number> {
    return this._percentage.asObservable();
  }

  async update() {
    if (this.platform.is("android") && this.platform.is("hybrid")) {
      if (this.httpConfig.fromStore) {
        const packageName = await this.appVersion.getPackageName();
        Market.open(packageName);
      } else {
        const packageVC = (await this.appVersion.getVersionCode()) as number;
        if (this.httpConfig.apkVersion > packageVC) {
          const updateUrl = this.httpConfig.androidApkUrl;
          const fileName = Date.now()
            .toString()
            .concat("-", this.httpConfig.apkName);

          const dOption: DownloadFileOptions = {
            url: updateUrl,
            path: fileName,
            directory: Directory.Documents,
            progress: true,
          };

          Filesystem.addListener("progress", (e) => {
            let percentage = (e.bytes * 100) / e.contentLength;
            this._percentage.next(Math.round(percentage));
          });

          Filesystem.downloadFile(dOption)
            .then((resp) => {
              setTimeout(() => {
                this.fileOpener
                  .open(resp.path, "application/vnd.android.package-archive")
                  .catch((err) => {
                    if (err.status == 9 && this.countCall < 2) {
                      this.countCall++;
                      this.update();
                    } else {
                      //alert( "an error occured during installation!" + JSON.stringify(err));
                      this.displayErrorService.openModalWithComponent(
                        {
                          message:
                            "An error occurred during installation! Please click download button to downloadthe latest version " +
                            JSON.stringify(err),
                          messageType: MessageType.ERROR,
                        },
                        "Download"
                      );
                    }
                  });
              }, 500);
            })
            .catch((err) => {
              //alert("an error occured during download! " + JSON.stringify(err));
              this.displayErrorService.openModalWithComponent(
                {
                  message:
                    "An error occurred while downloading! Please click download button to download the latest version " +
                    JSON.stringify(err),
                  messageType: MessageType.ERROR,
                },
                "Download"
              );
              console.log(err);
            });
        }
      }
    } else if (this.platform.is("ios") && this.platform.is("hybrid")) {
      //handle ios update
    } else if (!this.platform.is("hybrid")) {
      sessionStorage.clear();
      location.assign(location.origin);
    }
  }
}
