import { Component, OnInit, Input, SimpleChanges, SimpleChange, OnChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { StateService } from '@app/private/services/state.service';
import { HttpConfigService } from '@app/core/services/http/http-config.service';
import { DisplayErrorService } from '@app/core/services/error/display-error.service';
import { MessageType } from '@app/core/enum/message-type.enum';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css'],
})
export class NavComponent implements OnInit, OnChanges {

  @Input()
  braindcrumb: string;
  
  desactivate = false

  currentNav: string;

  a: string;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    public stateService: StateService,
    private configService: HttpConfigService,
    private displayErrorService: DisplayErrorService) {

    this.stateService.logoutEvent.subscribe((data) => {
      this.saveCurrentNav();
    });
  }

  saveCurrentNav() {
    if (this.currentNav)
      this.cookieService.set('currentNav', this.currentNav);
  }

  ngOnInit(): void {

    const currentPath = this.router.url.split('/')[2];
    if (this.cookieService.get('currentNav')) {
      this.currentNav = this.cookieService.get('currentNav');
    } else if (currentPath) {
      this.currentNav = currentPath;
    } else {
      this.currentNav = this.configService.defaultNav;
    }

    setTimeout(() => {
      document.getElementById(this.currentNav)?.click();
    }, 500);

  }

  changeStyle(id) {
    if(id !="dashboard" && localStorage.getItem("isGeneratedPassword")=="true"){
        id = "dashboard"

        this.displayErrorService.openModalWithComponent({message: "Please, you MUST change your password !!!", messageType:MessageType.WARNING});

        //document.getElementById(id).click();
    }    
    const nb = document.getElementsByClassName("nav-link").length;
    for (let index = 0; index < nb; index++) {
      document.getElementsByClassName("nav-link").item(index).removeAttribute("style");
    }
    document.getElementById(id).setAttribute("style", "color: white; background-image: url(assets/img/left.png), url(assets/img/right.png),url(assets/img/menuliactive2.jpg)");
    this.currentNav = id;

    document.getElementById(id).click()
  }

  ngOnChanges(changes: SimpleChanges) {
    const currentItem: SimpleChange = changes.braindcrumb;
    if (currentItem.currentValue) {
      this.currentNav = currentItem.currentValue.pop();
      //give 0.5s to the view to update the currentNav state attribute before the click
      setTimeout(() => {
        if (currentItem.currentValue) {
          this.changeStyle(this.currentNav);
          document.getElementById(this.currentNav).click();
          this.braindcrumb = ""; /** free the braincrumb to avoid resending the braincrumb with update-profile when the user clicks on dashboard instead of update-profile */
        }
      }, 500);
    }
  }

}
