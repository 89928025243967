import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WorkingspaceRoutingModule } from './workingspace-routing.module';
import { WorkingspaceComponent } from './workingspace.component';
import { TreeComponent } from './components/tree/tree.component';
import { ContentComponent } from './components/content/content.component';
import { HeaderComponent } from './components/header/header.component';
import { OrderModule } from 'ngx-order-pipe';
import { ShContextMenuModule } from 'ng2-right-click-menu';
import { StateService } from './services/state.service';

@NgModule({
  declarations: [
    WorkingspaceComponent,
    TreeComponent,
    ContentComponent,
    HeaderComponent
  ],
  imports: [CommonModule, OrderModule, ShContextMenuModule, WorkingspaceRoutingModule],
  providers: [StateService]
})
export class WorkingspaceModule {}
