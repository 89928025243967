import { AfterContentInit, Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Platform } from "@ionic/angular";
import { DownloadService } from "./services/download.service";
import { HttpConfigService } from "@app/core/services/http/http-config.service";

@Component({
  selector: "app-download",
  templateUrl: "./download.component.html",
  styleUrls: ["./download.component.css"],
})
export class DownloadComponent implements OnInit, AfterContentInit {
  apkFound: number = -1;
  message: string = "";
  download: any;
  timeLeft = 6;
  donwloadLunched: boolean = false;
  countLunched: boolean = false;

  constructor(
    private downloadService: DownloadService,
    private router: Router,
    public httpConfig: HttpConfigService,
    private platform: Platform
  ) {}

  ngAfterContentInit(): void {}

  ngAfterContentChecked() {
    if (this.httpConfig.androidApkUrl && this.donwloadLunched == false) {
      this.donwloadLunched = true;
      this.downloadApk();
    }
  }

  ngOnInit(): void {}

  ngOnChanges() {}

  goToHome() {
    setTimeout(() => {
      this.router.navigate(["/public/"]);
    }, 2000);
  }

  downloadApk() {
    if (this.platform.is("android")) {
      let apkLink = this.httpConfig.androidApkUrl;
      this.apkFound = 0;
      //apkLink = "https://www.koossery.com/farm/crops.TEST/grape/black_measles/treatments/basf/serifel/black_measles.basf.treatment.video.mp4";
      this.downloadService
        .download(apkLink, this.httpConfig.apkName)
        .subscribe((data) => {
          this.download = data;
          if (this.download.progress == 100 && !this.countLunched) {
            this.countLunched = true;
            let t = setInterval(() => {
              this.timeLeft--;
              if (this.timeLeft == 0) {
                clearInterval(t);
                this.goToHome();
              }
            }, 1000);
          }
        });
    } else {
      if (this.platform.is("ios")) {
        this.message = "app.public.download.messageIos"; //"koosseryFarm: not yet available for ios";
      } else if (!this.platform.is("hybrid")) {
        this.message = "app.public.download.messageSmartphone"; //"koosseryFarm: download failed because your device is not a smartphone";
      }
      this.apkFound = 1;
      this.goToHome();
    }
  }
}
