import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpConfigService } from '@app/core/services/http/http-config.service';
import { NodeModel } from '../models/node.model';

@Injectable({
  providedIn: 'root',
})
export class FilesFacadeService {
  includeSub = true;
  constructor(
    private httpConfig: HttpConfigService,
    private httpClient: HttpClient
  ) {}

  
getChildrenOfRootFolder(companyID: string, deskID: string, includeSub: boolean){
    const url = this.httpConfig.serverUrl.concat(':' + this.httpConfig.port + 
    "/koosserydesk.workingspace/service/workingspace/getChildrenOfRootFolder?companyID="+companyID+"&deskID="+deskID+"&includeSub="+includeSub);
    return this.httpClient.get<NodeModel>(url);
}

  getChildrenOfFolder(companyID: string, deskID: string, parentID: string, includeSub: boolean){
    const url = this.httpConfig.serverUrl.concat(':' + this.httpConfig.port + 
    "/koosserydesk.workingspace/service/workingspace/getChildrenOfFolder?companyID="+companyID+"&deskID="+deskID+"&parentID="+parentID+"&includeSub="+includeSub);
    return this.httpClient.get<NodeModel>(url);
  }

  //http://@IP:port/koosserydesk.workingspace/service/workingspace/getChildrenOfRootFolder?companyID={companyID}&deskID={deskID}&includeSubFolder=trueOrFalse
  //http://@IP:port/koosserydesk.workingspace/service/workingspace/getChildrenOfFolder?companyID={companyID}&deskID={deskID}&parentID={parentID}&includeSubFolder=trueOrFalse



  /*getNodeData(companyID: string, deskID: string, parentID: string, includeSubFolder: boolean): Promise<any>{
      const url = this.httpConfig.serverUrl.concat(':' + this.httpConfig.port);
      return this.httpClient.get(url+"/koosserydesk.workingspace/service/workingspace/getTreeOfFile?companyID="+ companyID + "&deskID=" + deskID + "&parentID=" + parentID + "&includeSubFolder=" + includeSubFolder).toPromise();
  }*/
  

  /*getChildrenOfRootFolder(companyID: string, deskID: string, includeSub: boolean): Promise<any>{
      const url = this.httpConfig.serverUrl.concat(':' + this.httpConfig.port);
      return this.httpClient.get(url+"/koosserydesk.workingspace/service/workingspace/getChildrenOfRootFolder?companyID="+ companyID + "&deskID=" + deskID + "&includeSub=" + includeSub).toPromise();
  }

  getChildrenOfFolder(companyID: string, deskID: string, parentID: string, includeSub: boolean): Promise<any>{
      const url = this.httpConfig.serverUrl.concat(':' + this.httpConfig.port);
      return this.httpClient.get(url+"/koosserydesk.workingspace/service/workingspace/getChildrenOfFolder?companyID="+ companyID + "&deskID=" + deskID + "&parentID=" + parentID + "&includeSub=" + includeSub).toPromise();
  }*/

}
