import { Component, OnInit, AfterViewInit } from '@angular/core';
import { StateService } from './services/state.service';

@Component({
  selector: 'app-private',
  templateUrl: './private.component.html',
  styleUrls: ['./private.component.css'],
})
export class PrivateComponent implements OnInit, AfterViewInit {
  
  currentDeskPath: string;
  braindcrumb: string;

  constructor(private stateService: StateService) { 
    this.stateService.selectedCurrentDeskEvent.subscribe(data=>{
      if(data)
      this.currentDeskPath = data.currentDeskPath;
    });

    this.stateService.updateProfileEvent.subscribe(
      (data)=>{
        if(data.emitter=="app/modules/private/components/header/HeaderComponent")
            this.braindcrumb = data.braindcrumb;
      }
    )
  }

  ngOnInit() {}

  ngAfterViewInit(): void {
    /**
     * block the browser's backward event (<== | ) 
     */
    history.pushState(null, document.title, location.href);
    window.addEventListener('popstate', function (event) {
        history.pushState(null, document.title, location.href);
    });
  }

}
