import {
  AfterViewChecked,
  Component,
  Input,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
  ElementRef,
  ViewChild,
  OnChanges,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { MessageType } from "@app/core/enum/message-type.enum";
import { DisplayErrorService } from "@app/core/services/error/display-error.service";
import { StateService } from "@app/public/services/state.service";
import {
  Camera,
  CameraResultType,
  CameraSource,
  Photo,
} from "@capacitor/camera";
import { Capacitor } from "@capacitor/core";
import { Directory, Filesystem } from "@capacitor/filesystem";
import { Platform } from "@ionic/angular";
import { SpeechRecognition } from "@ionic-native/speech-recognition";

declare var $: any;
import * as $ from "jquery";
import { TranslocoService } from "@ngneat/transloco";
import { VoiceService } from "@app/core/services/voice/voice.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-input-data",
  templateUrl: "./input-data.component.html",
  styleUrls: ["./input-data.component.css"],
})
export class InputDataComponent
  implements OnInit, AfterViewChecked, OnDestroy, OnChanges {
  @Input() crops;
  @Input() selectedCrop = null;
  @Input() buttonDimension;
  @Output() searchEvent = new EventEmitter();
  @Output() fileUploadedEvent = new EventEmitter();
  @Output() pictureTakenEvent = new EventEmitter();
  @Output() retreiveCropsEvent = new EventEmitter();
  @Output() diagnoseCropEvent = new EventEmitter();
  @Output() growCropEvent = new EventEmitter();

  firstNode: string = null;
  displayLoader: boolean = false;
  selectedFile = null;
  selectedFileUrl;
  document: File;
  plantLeafName: any;
  predictionForm: FormGroup;
  searchText = "";
  showTextForm: boolean = false;
  searchResults: String[] = [];
  @ViewChild("inputEl") inputEl: ElementRef<HTMLInputElement>;

  seachOption: boolean = false;
  staticVal: string = "";
  currentVal: string = "";
  displayMenu = true;

  spinner = false;
  sub: Subscription[] = [];

  constructor(
    private sanitizer: DomSanitizer,
    private displayErrorService: DisplayErrorService,
    private router: Router,
    private fb: FormBuilder,
    public platform: Platform,
    private stateService: StateService,

    private translocoService: TranslocoService,
    public voiceService: VoiceService
  ) {
    /**
     * after each 3s, we tried fetching the list of crop from the server, if that list is empty.
     * the sendRetrieveCropEvent is sent with false for the error dialog box not to be display in case of error, since this is a background process
     */
    setInterval(() => {
      if (this.crops == null && navigator.onLine) {
        this.sendRetrieveCropEvent(false);
      }
    }, 3000);
  }

  ngOnInit(): void {
    try {
      if (localStorage.getItem("selectedCrop") !== null) {
        this.selectedCrop = JSON.parse(localStorage.getItem("selectedCrop"));
        //this.selectCrop(this.selectedCrop);
      }
    } catch (error) {
      this.selectedCrop = null;
    }

    this.predictionForm = this.fb.group({
      document: [""],
      plantLeafName: [""],
      searchText: [""],
    });

    $(function () {
      $('[data-toggle="popover"]').popover();
    });
  }

  ngAfterViewChecked() {}

  oncancelLoading($event) {
    this.displayLoader = false;
  }

  onClickDiagnoseCrop() {
    if (this.selectedCrop == null) {
      this.displayErrorService.openModalWithComponent({
        message: "Choose a crops below",
        messageType: MessageType.ERROR,
      });
      return;
    }

    const data = { type: "diagnose", selectedCrop: this.selectedCrop };
    this.diagnoseCropEvent.emit(data);
  }

  onClickGrowCrop() {
    const data = { type: "grow", selectedCrop: this.selectedCrop };
    this.growCropEvent.emit(data);
  }

  //==================================start camera functions===============================================

  async openCamera() {
    this.displayLoader = true;
    const result = await this.takePicture();
    this.displayLoader = false;
    //send the picture to the mother component (home or diagnostic component)
    this.pictureTakenEvent.emit(result.file);
  }

  public async takePicture() {
    // Take a photo
    const capturedPhoto = await Camera.getPhoto({
      resultType: CameraResultType.Uri, // file-based data; provides best performance
      source: CameraSource.Camera, // automatically take a new photo with the camera
      quality: 90,
      height: 600,
    });

    // create file with the captured picture
    const savedImageFile = await this.createFile(capturedPhoto);

    const imageFile = this.getFileFromBase64(
      savedImageFile.data,
      savedImageFile.filepath.split("/").pop()
    );

    return { file: imageFile, url: savedImageFile.webviewPath };
  }

  public getFileFromBase64(string64: any, fileName: string) {
    const trimmedString = string64.includes("png")
      ? string64.replace("data:image/png;base64,", "")
      : string64.replace("data:image/jpeg;base64,", "");
    const imageContent = atob(trimmedString);
    const buffer = new ArrayBuffer(imageContent.length);
    const view = new Uint8Array(buffer);

    for (let n = 0; n < imageContent.length; n++) {
      view[n] = imageContent.charCodeAt(n);
    }
    const type = "image/jpeg";
    const blob = new Blob([buffer], { type });
    return new File([blob], fileName, {
      lastModified: new Date().getTime(),
      type,
    });
  }

  /**
   * ===========================================================================================================================
   * create file with the taken picture
   * @param photo
   * @returns created file
   */
  private async createFile(photo: Photo) {
    // Convert photo to base64 format, required by Filesystem API to save
    const base64Data = await this.readAsBase64(photo);

    // Write the file to the data directory
    const fileName = new Date().getTime() + ".jpeg";
    const savedFile = await Filesystem.writeFile({
      path: fileName,
      data: base64Data,
      directory: Directory.Documents,
    });

    if (this.platform.is("hybrid")) {
      // Display the new image by rewriting the 'file://' path to HTTP
      // Details: https://ionicframework.com/docs/building/webview#file-protocol
      return {
        filepath: savedFile.uri,
        data: base64Data,
        webviewPath: Capacitor.convertFileSrc(savedFile.uri),
      };
    } else {
      // Use webPath to display the new image instead of base64 since it's
      // already loaded into memory
      return {
        filepath: fileName,
        data: base64Data,
        webviewPath: photo.webPath,
      };
    }
  }

  private async readAsBase64(photo: Photo) {
    // "hybrid" will detect Cordova or Capacitor
    if (this.platform.is("hybrid")) {
      // Read the file into base64 format
      const file = await Filesystem.readFile({
        path: photo.path,
      });

      return file.data;
    } else {
      // Fetch the photo, read as a blob, then convert to base64 format
      const response = await fetch(photo.webPath!);
      const blob = await response.blob();

      return (await this.convertBlobToBase64(blob)) as string;
    }
  }

  private convertBlobToBase64 = (blob: Blob) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(blob);
    });

  //======================================== end camera functions===================================

  //============================================== drag and drop/upload functions=======================
  onsendDroppedFileEvent(event) {
    this.onSelectFile(event);
  }

  onSelectFile(event) {
    let type: string = event.dataTransfer
      ? event.dataTransfer.files[0].type
      : event.target.files[0].type;
    let file = event.dataTransfer
      ? event.dataTransfer.files[0]
      : event.target.files[0];
    let imageTypes = [
      "image/png",
      "image/gif",
      "image/bmp",
      "image/jpeg",
      "image/jpg",
      "image/webp",
      "image/x-icon",
      "image/tiff",
      "image/svg+xml",
      "image/apng",
      "image/avif",
      "fichier/png",
      "fichier/gif",
      "fichier/bmp",
      "fichier/jpeg",
      "fichier/jpg",
      "fichier/webp",
      "fichier/x-icon",
      "fichier/tiff",
      "fichier/svg+xml",
      "fichier/apng",
      "fichier/avif",
    ];

    if (imageTypes.includes(type)) {
      //send the file to the mother component (home or diagnostic component)
      this.fileUploadedEvent.emit(file);
    } else {
      let name = "pant"; // this.nameWorkflow.replace('Desease Tool', ' ');
      this.displayErrorService.openModalWithComponent({
        message: `please load your ${name}'s  leaf image in the box to diagnose it`,
        messageType: MessageType.ERROR,
      });
    }
  }

  //============================================end drag and drop functions ====================================
  /**
   * @param showError is true if the error dialog box should be displayed in case of error during the retrieval process
   */
  sendRetrieveCropEvent(showError) {
    this.retreiveCropsEvent.emit(showError);
  }
  //============================================ start search by crop name ====================================

  onSearch(searchText) {
    if (searchText.trim() == "" || searchText.trim() == " ") return;
    this.displayMenu = false;
    this.spinner = true;
    setTimeout(() => {
      this.spinner = false;
    }, 1500);

    if (this.crops == null) return this.sendRetrieveCropEvent(true);

    let firstCropFound = null;
    let k = 0;
    while (firstCropFound == null && this.crops?.length > k) {
      if (this.crops[k].name.includes(searchText.toLowerCase())) {
        // alert(`crop: ${this.crops[k].name} && st: ${searchText.toLowerCase()}`);
        firstCropFound = this.crops[k];
      }
      k++;
    }

    if (firstCropFound != null) {
      return this.onSelectCrop(firstCropFound);
    } else {
      if (!this.showTextForm) {
        this.toggleSearchTextForm();
      }

      this.searchText = searchText;

      return this.displayErrorService.openModalWithComponent({
        message: "unknown crop " + searchText,
        messageType: MessageType.WARNING,
      });
    }
  }

  onSelectCrop(crop) {
    this.searchEvent.emit(crop.id); //crop.id
    this.searchText = crop.name;
    this.displayMenu = false;
    this.searchText = crop.name;
  }

  toggleSearchTextForm() {
    $(".popover").popover("hide");
    this.showTextForm = !this.showTextForm;
    this.searchText = null;
  }

  onClickSearch() {
    let lang = JSON.parse(localStorage.getItem("defaultLang"));
    if (lang.code != "kw-RW") {
      this.listen("app.shared.input-data.searchMessageSpeech");
    }
  }

  currentNode: ChildNode = null;

  selectItem($event) {
    setTimeout(() => {
      if (this.currentNode && $event.key != "Backspace") {
        //$event.charCode == 8 || $event.which != 8 ||
        this.displayMenu = true;
        this.currentNode["className"] = this.currentNode["className"]?.replace(
          "select",
          ""
        );
      }

      if (
        $event.key != "Enter" &&
        document.getElementById("listItems")?.childNodes != null &&
        $event.key != "ArrowUp" &&
        $event.key != "ArrowDown"
      ) {
        // $event.keyCode != 13     $event.keyCode != 38 && $event.keyCode != 40

        if ($event.code == "Backspace" || $event.key == "Backspace") {
          //document.getElementById("listItems")?.childNodes.forEach(el => (el['className'] = el['className'].replace("select", '')));
          this.currentNode["className"] = this.currentNode["className"].replace(
            "select",
            ""
          );
        }

        this.currentNode = document.getElementById("listItems")?.firstChild;
        this.currentNode["className"] =
          this.currentNode["className"] + " select";
        document.getElementById("inputEl")["value"] =
          document.getElementById("inputEl")["value"] == this.firstNode
            ? this.firstNode
            : this.searchText;
      } else {
        this.displayMenu = true;
        //$event.keyCode == 38
        if (
          $event.key == "ArrowUp" &&
          document.getElementById("listItems")?.childNodes != null
        ) {
          // && !this.platform.is('hybrid')
          if (this.currentNode["innerText"] != this.firstNode) this.selectUp();
          else
            this.currentNode["className"] =
              this.currentNode["className"] + " select";
        }
        if (
          $event.key == "ArrowDown" &&
          document.getElementById("listItems")?.childNodes != null
        ) {
          // && !this.platform.is('hybrid') $event.keyCode == 40
          this.selectDown();
        }
      }

      if ($event.key == "Enter") {
        //$event.keyCode == 13
        this.currentNode["className"] = this.currentNode["className"].replace(
          "select",
          ""
        );
        //this.searchText = this.currentNode["innerText"];fsqf
        this.displayMenu = false;
        document.getElementById("inputEl")["value"] = this.searchText;
      }
    }, 10);
  }

  selectUp() {
    let n = this.currentNode.previousSibling;
    if (n != null) {
      n["className"] = n["className"] + " select";
      this.currentNode["className"] = this.currentNode["className"].replace(
        "select",
        ""
      );
      this.currentNode = n;
      document.getElementById("inputEl")["value"] =
        this.currentNode["innerText"] != undefined
          ? this.currentNode["innerText"]
          : this.firstNode;
    } else {
      this.currentNode["className"] = this.currentNode["className"].replace(
        "select",
        ""
      );
      document.getElementById("inputEl")["value"] = this.firstNode;
    }
  }

  selectDown() {
    let n = this.currentNode.nextSibling;
    if (n != null) {
      n["className"] = n["className"] + " select";
      this.currentNode["className"] = this.currentNode["className"].replace(
        "select",
        ""
      );
      this.currentNode = n;
      document.getElementById("inputEl")["value"] =
        this.currentNode.nextSibling != null
          ? this.currentNode["innerText"]
          : this.searchText; //this.firstNode;
    } else {
      this.currentNode["className"] = this.currentNode["className"].replace(
        "select",
        ""
      );
      document.getElementById("inputEl")["value"] = this.firstNode;
    }

    if (n == this.currentNode.lastChild) {
      this.currentNode["className"] = this.currentNode["className"].replace(
        "select",
        ""
      );
      this.selectItem(0);
    }
  }

  close() {
    this.searchText = "";
    document.getElementById("inputEl")["value"] = "";
  }

  /** input the user voice */
  listen(msg) {
    let s1: Subscription;
    let lang = JSON.parse(localStorage.getItem("defaultLang"));

    if (lang.code == "kw-RW") {
      this.displayErrorService.openModalWithComponent({
        message: `The speech recognition is not available in ${lang.name}`,
        messageType: MessageType.ERROR,
      });

      return;
    }

    s1 = this.translocoService.selectTranslate(msg).subscribe((prompt_msg) => {
      // Check permission
      SpeechRecognition.hasPermission().then((hasPermission: boolean) => {
        const options = {
          language: lang["code"],
          matches: 3,
          prompt: prompt_msg, // Android only
          showPopup: false, // Android only
          showPartial: false,
        };
        if (!hasPermission) {
          // Request permissions
          SpeechRecognition.requestPermission().then(
            () => {
              this.initVoiceSearch(prompt_msg, options);
            },
            () => alert("Permission denied!")
          );
        } else {
          this.initVoiceSearch(prompt_msg, options);
        }
      });
    });
    this.sub.push(s1);
  }

  async initVoiceSearch(msg: string, options) {
    let toggleSound: boolean = JSON.parse(localStorage.getItem("toggleSound"));
    if (toggleSound == false)
      localStorage.setItem("toggleSound", JSON.stringify(true));
    this.voiceService.speak(msg, false);
    setTimeout(async () => {
      if (!toggleSound)
        localStorage.setItem("toggleSound", JSON.stringify(false));
      this.startListening(options);
    }, 4000);
  }

  repeat = 0;
  startListening(options) {
    //stop any vocale currently playing;
    this.voiceService.speak("", false);
    let s2: Subscription;
    // Start the recognition process
    s2 = SpeechRecognition.startListening(options).subscribe(
      (matches: Array<string>) => {
        this.showTextForm = true;
        let found = false,
          i = 0;
        while (!found && i < this.crops.length) {
          let cw = [];
          if (this.crops[i].name.indexOf("(") > -1)
            cw = this.crops[i].name
              .replace(" ", "")
              .replace("(", " ")
              .replace(")", "")
              .split(" ");
          else if (this.crops[i].name.indexOf(",") > -1)
            cw = this.crops[i].name.split(",");
          else cw[0] = this.crops[i].name;
          let k = 0;
          while (!found && k < cw.length) {
            //alert("w = "+cw[k]);
            if (
              matches[0].indexOf(cw[k].trim()) > -1 ||
              matches[1]?.indexOf(cw[k].trim()) > -1 ||
              matches[2]?.indexOf(cw[k].trim()) > -1
            ) {
              found = true;
            }
            k++;
          }
          i++;
        }

        this.searchText = found ? this.crops[i - 1].name : matches[0];
        this.onSearch(this.searchText);
      },
      (onerror) => {
        console.log("error: " + onerror);
        if (this.repeat < 2) {
          this.repeat++;
          this.voiceService.speak("app.shared.input-data.listeningSpeechError");
          setTimeout(() => {
            this.startListening(options);
          }, 3500);
        } else if (this.repeat == 2) {
          this.repeat++;
          this.voiceService.speak(
            "app.shared.input-data.listeningSpeechError2"
          );
          setTimeout(() => {
            this.startListening(options);
          }, 3500);
        }
      }
    );
    this.sub.push(s2);
  }

  ngOnDestroy() {
    this.voiceService.speak("");
    $(".popover").popover("hide");
    this.sub.forEach((s) => {
      s.unsubscribe();
    });
  }
  ngOnChanges() {}
}
