import { Injectable } from '@angular/core';
import { HttpConfigService } from '@app/core/services/http/http-config.service';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';

@Injectable()
export class ForgotPasswordFacadeService {

  servicePath = 'koosserydesk.directory/service/user/resetPassword';
 

  constructor(
    private httpConfig: HttpConfigService,
    private httpClient: HttpClient
  ) {}

  public resetPassword(email: string) {
    
    const url = this.httpConfig.serverUrl.concat(
      ':' + this.httpConfig.port + '/' + this.servicePath
    );

    const params = {email:email};
    
    return this.httpClient
      .get(url, {
        params: params,
        observe: 'response'
      });
      
    }
}
