import { Injectable } from "@angular/core";
import { HttpConfigService } from "@app/core/services/http/http-config.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";
import { UserModel } from "../../../../../../shared/models/user.model";
import { Observable, of } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class SignUpFacadeService {
  //servicePath = 'koosserydesk.directory/service/user/create';
  createUser = "koosserydesk.directory/service/user/create";
  getCode = "koosserydesk.directory/service/user/generateSubscriptionCode";

  constructor(
    private httpConfig: HttpConfigService,
    private httpClient: HttpClient
  ) {}

  /*public getSubscriptionCode1(uid: string, email: string, phoneNumber: string){
    const url = this.httpConfig.serverUrl.concat(':' + this.httpConfig.port + 
    "/koosserydesk.directory/service/user/generateSubscriptionCode?uid="+uid+"&email="+email+"&phoneNumber="+phoneNumber);
    return this.httpClient.get<string>(url);
}*/

  /**
   *
   * @param uid
   * @param email
   * @param phoneNumber
   *
   * return a subcription code and send this code by email to the user
   */
  public getSubscriptionCode(uid: string, email: string, phoneNumber: string) {
    const params = { uid, email, phoneNumber };

    const url = this.httpConfig.serverUrl.concat(
      ":" + this.httpConfig.port + "/" + this.getCode
    );

    return this.httpClient.get(url, { params: params, observe: "response" });
  }

  public saveUser(user: UserModel) {
    //  const proxyurl = "https://cors-anywhere.herokuapp.com/";
    let headers = new HttpHeaders();

    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");

    const url = this.httpConfig.serverUrl.concat(
      ":" + this.httpConfig.port + "/" + this.createUser
    );

    return this.httpClient.post(url, user, { headers });
  }
}
