import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TextToSpeech } from "@ionic-native/text-to-speech/ngx";
import { Platform } from "@ionic/angular";
import { TranslocoService } from "@ngneat/transloco";
import { Subscription } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class VoiceService {
  sub: Subscription = new Subscription();
  audio = null;
  constructor(
    protected textToSpeech: TextToSpeech,
    protected translocoService: TranslocoService,
    private http: HttpClient,
    private platform: Platform
  ) {}

  kinyaSpeak(textToSpeak) {
    let headers = new HttpHeaders();

    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");

    const subc = this.http
      .post(
        "https://mbazanlp-kinyarwanda-text-to-speech.hf.space/run/predict",
        {
          data: [textToSpeak],
        },
        { headers }
      )
      .subscribe(
        (response) => {
          console.log(response);
          this.audio = new Audio();
          this.audio.src =
            "https://mbazanlp-kinyarwanda-text-to-speech.hf.space/file=" +
            response["data"][0].name;
          this.audio.load();
          this.audio.play();
        },
        (error) => {}
      );
    //subc.unsubscribe();
  }

  speak(text: string, toTranslate: boolean = true) {
    //alert("speak "+text);
    if(!this.platform.is("hybrid")) return;
    let toggleSound: boolean = JSON.parse(localStorage.getItem("toggleSound"));

    if (toggleSound == false) {
      return;
    }

    let textToSpeak = text;
    const lcode = JSON.parse(localStorage.getItem("defaultLang"))["code"];
    //alert(lcode)
    if(toTranslate) {
      this.sub.add(
        this.translocoService
          .selectTranslate(textToSpeak)
          .subscribe((translated_text) => {
            textToSpeak = translated_text;
            this.sub.unsubscribe();
            if (lcode == "kw-RW") {
              this.kinyaSpeak(textToSpeak);
              return;
            }
            this.textToSpeech
              .speak({
                text: textToSpeak,
                locale: lcode,
                rate: lcode == "en-US" ? 0.7 : 0.8,
              })
              .then(
                () => {
                  this.textToSpeech.stop();
                },
                (error) => {
                  console.log(error);
                }
              );
          })
      );
    } else {
      if (lcode == "kw-RW") {
        if (textToSpeak) this.kinyaSpeak(textToSpeak);
        else {
          this.audio?.pause();
        }
        return;
      }

      this.textToSpeech
        .speak({
          text: textToSpeak,
          locale: lcode,
          rate: lcode == "en-US" ? 0.7 : 0.8,
        })
        .then(
          () => {
            this.textToSpeech.stop();
            console.log("done reading");
          },
          (error) => {
            console.log(error);
            //alert(error);
          }
        );
    }
  }
}
