import { Component, OnInit, Output, EventEmitter, Input, SimpleChanges } from '@angular/core';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  @Input() dataHeader;
  @Output() clickFoldersEvent = new EventEmitter<any>();
  
 constructor() { }
  
  ngOnInit(): void {}

  emitClickFoldersEvent(id){
    this.clickFoldersEvent.emit(id);
  }

  
}
