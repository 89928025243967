import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Platform } from "@ionic/angular";
import { routerTransition } from "./animation";

@Component({
  selector: "app-public",
  templateUrl: "./public.component.html",
  styleUrls: ["./public.component.css"],
  animations: [routerTransition],
})
export class PublicComponent implements OnInit {
  toggleSound = true;

  constructor(public platform: Platform, private router: Router) {
    document.addEventListener("backbutton", function (event) {
      event.preventDefault();
      if (confirm("close koosseryFarm ? ")) {
        navigator["app"].exitApp();
      }
    });
  }

  ngOnInit(): void {
    try {
      this.toggleSound = JSON.parse(localStorage.getItem("toggleSound"));
      if (this.toggleSound == null || this.toggleSound == undefined) {
        this.toggleSound = true;
        localStorage.setItem("toggleSound", JSON.stringify(true));
      }
    } catch (err) {
      this.toggleSound = true;
      localStorage.setItem("toggleSound", JSON.stringify(true));
    }
  }

  ngOnDestroy(): void {}
}
