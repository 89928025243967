import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { AppVersion } from "@ionic-native/app-version/ngx";
import { Platform } from "@ionic/angular";

@Injectable()
export class AuthenticatorInterceptor implements HttpInterceptor {
  constructor(private appVersion: AppVersion, public platform: Platform) {}
  token: string = null;

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.getVersion();
    if (this.token != null) {
      if (
        !request.url.includes("android.xml") &&
        !request.url.includes("mp4") &&
        !request.url.includes(".apk") &&
        !request.url.includes("sendMail") &&
        !request.url.includes(".json") &&
        !request.url.includes(".io")&&
        !request.url.includes("speech.hf.space")
      ) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${this.token}`,
          },
        });
      }
    }
    return next.handle(request);
  }

  async getVersion() {
    if (this.platform.is("hybrid")) {
      this.token = await this.appVersion.getVersionNumber();
    } else {
      this.token = sessionStorage.getItem("token"); //1.2.6
    }
  }
}
