import { Injectable } from '@angular/core';
import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivate
} from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';

@Injectable()
export class PermissionGuard implements CanActivate {

  //will contain user's roles
  roles: any[];

  redirectUrl: string;

  constructor(
    private router: Router,
    private permissionsService: NgxPermissionsService
  ) {


  }
  /** canActivate : Guard method.
   *  Determine if the user logs in or not!
   *  If the user is logged in then the Guard returns true and navigation continues.
   *  If the user is not logged in then we redirect him to the home page ('/').
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    
    const url: string = state.url;

    if (route.data?.roles &&
        route.data.roles.some((routerRole: any) =>
        this.roles?.some(userRole => routerRole === userRole))) {
          this.permissionsService.loadPermissions(this.roles);
      return true;
    } else {
      this.redirectUrl = url;
      console.log('home page route called');
      this.router.navigate(['/public/']); //this.router.navigate(['/login/']);
      return false;
    }

  }
}
