import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrivateComponent } from './private.component';
import { PrivateRoutingModule } from './private-routing.module';
import { RouterModule } from '@angular/router';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { NavComponent } from './components/nav/nav.component';
import { SharedModule } from '@app/shared/shared.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { WorkingspaceModule } from './modules/workingspace/workingspace.module';
import { StateService } from './services/state.service';
import { SecurityService } from './services/security.service';

@NgModule({
  declarations: [
    PrivateComponent,
    HeaderComponent,
    FooterComponent,
    NavComponent
  ],
  imports: [
    CommonModule,
    PrivateRoutingModule,
    WorkingspaceModule,
    SharedModule,
    RouterModule,
    BsDropdownModule,
    TabsModule.forRoot(),
    NgxPermissionsModule.forChild(),
  ],
  providers: [StateService, SecurityService]
})
export class PrivateModule {}
