import { Component, OnInit, Input, EventEmitter, Output, SimpleChanges, OnChanges } from '@angular/core';
import { StateService as PrivateStateService} from '@app/private/services/state.service';
import { Subscription } from 'rxjs';
import { NodeModel } from '../../models/node.model';
import { StateService } from '../../services/state.service';


@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.css'],
})
export class ContentComponent implements OnInit, OnChanges {
  @Input() contentFolder: NodeModel;
  @Output() clickFoldersEvent = new EventEmitter<any>();
  @Input() functionalities;
 
  curentBreadcrumb = null;
  savedBreadcrumb;  
  breadcrumb = null;
  breadcrumb2 = "";
  nameOfCurrentNode  = null
  allowedFunctionalities = []
  subscription: Subscription;
  currentDeskTitle

  listOfFunctionalities;
  constructor(private stateService: StateService, private privateStateService: PrivateStateService) {}

  ngOnInit(): void {}

  emitClickFoldersEvent(currentNode){
    if(currentNode.folder){
      this.clickFoldersEvent.emit(currentNode.id);
    }
    else{
      //this.loadFunctionalities('flatFile');
    }
  }
  onClickIcon(){
     this.loadFunctionalities('icon');
  }

  loadFunctionalities(selectedItem){
    this.listOfFunctionalities = this.functionalities[selectedItem];
    
  }

  /**
   * Allows to build the breadcrumb and remove the name of the desk in the new breadcrumb
   */
  builtBreadbrumb(){
    if(JSON.parse(localStorage.getItem("app/modules/private/modules/workingspace/workingspaceComponent.breadcrumb"))){
      this.breadcrumb = "";
      this.savedBreadcrumb = JSON.parse(localStorage.getItem("app/modules/private/modules/workingspace/workingspaceComponent.breadcrumb"));
      this.savedBreadcrumb?.forEach((element, last, array) => {
        if(last!=0){
          if (last === array.length - 1){ 
            this.breadcrumb = this.breadcrumb + element.name 
          }
          else{
            this.breadcrumb = this.breadcrumb + element.name+  "/" 
          }
        }
      });
    }

  }

  ngOnChanges(){
    this.builtBreadbrumb(); 
    /* Allows loading the functionalities to be activated for the node requested by the user, 
    after the execution of this function the list of functionalities to be activated is available*/
    //this.stateService.checkSpecificDeskFoldersConfig(this.breadcrumb, "action") 
    this.stateService.rolesConfig(this.breadcrumb, "action")
   // this.allowedFunctionalities = this.stateService.allowedFunctionalities
    this.allowedFunctionalities = this.stateService.allowedFunctionalities
    this.loadFunctionalities('folder');
  }
  
  /**
   *  Point 5/ of the workingspace.2 strategy
   *  In strategy we had thought that we could use the variable "this.stateService.allowedFunctionalities" of the stateService 
   *  to activate or deactivate the functionalities, but this poses this problem: Every time the user makes a right click on a
   *  folder of the component content or tree then the functionalities displayed in the form of buttons are also modified. 
   *  To solve this problem we create a local variable "allowedFunctionalities" in the content component which contains the list of 
   *  functionalities to be activated and each time the isAllowed(action) function will be called in case (2) the functionality 
   *  passed in parameter will either be activated (return true) or disable (return false). 
   */
  isAllowed(action, nameOfCurrentNode?, last?){
    if(nameOfCurrentNode){  // case (1) To activate or deactivate the functionalities when the user right clicks on a node
        if(this.nameOfCurrentNode == nameOfCurrentNode){  
            if(last)  this.nameOfCurrentNode = ""  // Used to manage point 1.2 of the strategy (Point 5/ of the workingspace.2 strategy) 
            if(this.stateService.allowedFunctionalities.includes(action)){
            //if(this.stateService.allowedFunctionalities.includes(action)){
              return true;
            }
              return false;   
        }
        else{
            this.nameOfCurrentNode = nameOfCurrentNode        
            //return this.stateService.checkSpecificDeskFoldersConfig(this.breadcrumb+"/"+nameOfCurrentNode, action);
            return this.stateService.rolesConfig(this.breadcrumb+"/"+nameOfCurrentNode, action);
      }
    }
    else{                 // case (2) To activate or deactivate the functionalities when the user clicks on a node
        if(this.allowedFunctionalities.includes(action)){
        //if(this.allowedFunctionalities.includes(action)){
          return true;
        }
        else{
          return false
        }
    }

    
  }
 

}  

    
