import { Injectable } from '@angular/core';
import { HttpResponse, HttpClient } from '@angular/common/http';
import { HttpConfigService } from '@app/core/services/http/http-config.service';
import { UserModel } from '@app/shared/models/user.model';
import { map } from 'rxjs/operators';

@Injectable()
export class HeaderFacadeService {
 
  getUserByKeyPath = 'koosserydesk.directory/service/user/find';


  constructor(
    private httpConfig: HttpConfigService,
    private httpClient: HttpClient
  ) {}

  getHeaderData(res: HttpResponse<any>|any) {
    let data = {
      username: res.body['uId'],
      listOfDesk: res.body['desks'],
      email: res.body['email'],
      company: res.body['company'],
      roles: res.body['roles']
    };
    return data;
  }

  getListOfDesk(companyID:string, email: string, uId: string) {
    const url = this.httpConfig.serverUrl.concat(
      ':' + this.httpConfig.port + '/' + this.getUserByKeyPath
    );
    const param = {companyID, searchExpression: email, uId:uId };

    return this.httpClient
      .get<UserModel>(url, {
        params: param,
        observe: 'response'
      }).pipe(map((user)=>{
        console.log(user);
          return user.body[0].desks;
      }));
    
  }
}
