import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { PublicRoutingModule } from "./public-routing.module";
import { SharedModule } from "@app/shared/shared.module";
import { NgxPermissionsModule } from "ngx-permissions";
import { SubscriptionModule } from "./modules/subscription/subscription.module";
import { HomeComponent } from "./components/home/home.component";
import { FooterComponent } from "./components/footer/footer.component";
import { PublicComponent } from "./public.component";
import { ToolsFacadeService } from "./services/tools-facade.service";
import { StateService } from "./services/state.service";
import { IonicModule } from "@ionic/angular";
import { PublicFacadeService } from "./services/state-facade.service";
import { SAVER, getSaver } from "./saver.provider";

import { MatButtonModule } from "@angular/material/button";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatCardModule } from "@angular/material/card";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ProgressbarModule } from "ngx-bootstrap/progressbar";
import { DownloadComponent } from "./components/download/download.component";
import { TRANSLOCO_SCOPE, TranslocoModule } from "@ngneat/transloco";
import { TranslocoRootModule } from "src/app/transloco-root.module";

@NgModule({
  declarations: [
    HomeComponent,
    FooterComponent,
    PublicComponent,
    DownloadComponent,
  ],
  imports: [
    PublicRoutingModule,
    SharedModule,
    NgxPermissionsModule.forChild(),
    SubscriptionModule,

    MatButtonModule,
    MatProgressBarModule,
    MatCardModule,
    FormsModule,
    //BrowserModule,
    //BrowserAnimationsModule,
    ReactiveFormsModule,
    ProgressbarModule.forRoot(),
    TranslocoRootModule,
    TranslocoModule,
  ],
  providers: [
    ToolsFacadeService,
    StateService,
    PublicFacadeService,
    { provide: SAVER, useFactory: getSaver },
  ],
})
export class PublicModule {}
