import {
  Component,
  OnInit,
  HostListener,
  OnDestroy,
} from '@angular/core';
import { HeaderModel } from './models/header.model';
import { HeaderFacadeService } from '@app/private/components/header/services/header-facade.service';
import { Router, ActivatedRoute} from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { StateService } from '@app/private/services/state.service';
import { Desk } from '@app/shared/models/user.model';
import { Subscription, Observable } from 'rxjs';
import { DisplayErrorService } from '@app/core/services/error/display-error.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  providers: [HeaderFacadeService],
})
export class HeaderComponent implements OnInit, OnDestroy {

  headerModel: HeaderModel;
  currentDesk: any;
  componentFullName: string;
  subscription: Subscription[] = [];
  desactivate = true
  //[disabled]="desactivate"

  constructor(private headerFaService: HeaderFacadeService,
    private router: Router,
    private cookieService: CookieService,
    private stateService: StateService,
    private displayErrorService: DisplayErrorService) {

    this.subscription[0] = this.stateService.logoutEvent.subscribe(
      (data) => {
        this.saveCurrentDesk();
      });

    this.subscription[1] = this.stateService.updateProfileEvent.subscribe(
      (data) => {
        if (data.emitter != this.componentFullName && data.value) {
          this.headerModel.email = data.value.email;
          this.headerModel.username = data.value.username;
          this.stateService.email = this.headerModel.email;
          this.stateService.roles = this.headerModel.roles;
        }
      }
    );

    this.subscription[2] = this.stateService.selectedCurrentDeskEvent.subscribe(
      (data) => {
        if (data) {
          this.currentDesk = data.currentDesk;
        }
      }
    );

    this.subscription[3] = this.stateService.updateListOfDeskEvent.subscribe(
      (data) => {
        this.getListOfDesk(data);
      }
    );

    this.componentFullName = "app/modules/private/components/header/HeaderComponent";
  }

  ngOnInit() {
    this.headerModel = this.headerFaService.getHeaderData(
      window.history.state.data
    );
    this.stateService.email = this.headerModel.email;
    this.stateService.company = this.headerModel.company;
    this.stateService.roles = this.headerModel.roles;
    this.retrieveCookieDesk();
  }

  getListOfDesk(d) {
    this.headerFaService.getListOfDesk(this.headerModel.company.id,this.headerModel.email,this.headerModel.username).subscribe(
      (data) => {
        
        this.headerModel.listOfDesk = data;
        /**
         * if the getListOfDesk is call with a desk and not with a boolean, that desk is set as currentDesk
         * 
         * it happends for instance when after renaming a desk you raise the updatelistOfDeskEvent which will call this method
         * in this case the renamed desk should remain the current desk in the new list of desk retrieved 
         */
        if(d?.id){
          this.currentDesk = d;
        }else{
          this.currentDesk = this.setDefaultDesk();
        }
        this.selectDesk(this.currentDesk.id);
      },
      error => {
        this.displayErrorService.openModalWithComponent(error);
      }
    );
  }
  userDesk(desk: Desk): boolean{
   // return true;
    return this.stateService.roles[desk.id]!=null;
    //return desk.memberIDs?.includes(this.stateService.email);
  }
  onClickUpdateProfile(email) {
    const objectEvent = {
      'braindcrumb': ["my-profile", "dashboard"],
      'email': email,
      'emitter': this.componentFullName
    };
    this.stateService.emitUpdateProfileEvent(objectEvent);
  }

  logOut() {
    this.stateService.emitLogoutEvent();
    this.router.navigate(['login']);
  }

  saveCurrentDesk() {
    if (this.currentDesk) {
      this.cookieService.set('currentDeskId', this.currentDesk.id, 7);
    }
  }

  

  /**
   * retrieve the Object Event containing current desk information in the cookie, 
   * 
   * check if the currentDesk in that object is among the user list of desk
   * 
   * and emit the selectCurrentDeskEvent with the currentDesk
   */
  retrieveCookieDesk() {
   
    if (this.cookieService.check('currentDeskId')) {

      const deskId = this.cookieService.get('currentDeskId');

        this.currentDesk = this.checkDesk(deskId);
    }

    //the recover desk is not amongs the user list of desk or there is no cookie
    if (!this.currentDesk){ 
      this.currentDesk = this.setDefaultDesk();
    }

      this.selectDesk(this.currentDesk?.id);
  }

  /**
   * this function is used in onInit() and getListOfDesk() functions
   * 
   * @param id deskId to be selected in the combobox
   */
  selectDesk(id){
    setTimeout(() => {
      document.getElementById(id)?.click();
    }, 300);
  }

/**
 * this function return the first desk in the list of desk to which the user belong
 * 
 */
  setDefaultDesk():Desk{
    let deskFound = false, i=0;
    while(!deskFound && i<this.headerModel.listOfDesk.length){
      let desks: Desk[]=[this.headerModel.listOfDesk[i]];
    while(!deskFound && desks?.length){
      let desk = desks.shift();
      if(this.userDesk(desk)){
       deskFound = true;
        return desk;
      }else{
        desk.subDesks?.forEach(sd => {
          desks.push(sd);
        });
      }
    }
    i++;
  }
  return null;
  }

  /**
   * this function checks if the deskId taken from the cookie is amognst the users' desks
   * @param deskId deskId taken from the cookie
   */
  checkDesk(deskId: string): Desk {
    let desks: Desk[], deskFound = false, i=0; 
    while(!deskFound && i<this.headerModel.listOfDesk.length){
       desks = [this.headerModel.listOfDesk[i]];
      while(!deskFound && desks?.length){
        let desk = desks.shift();
        if(desk.id == deskId){
         deskFound = true;
         return desk;
        }else{
          desk.subDesks?.forEach(sd => {
            desks.push(sd);
          });
        }
      }
      i++;
    }
    return null;
  }


  emitSelectedDeskEvent(desk, path, pathId) {

    if (this.userDesk(desk)) {
      this.currentDesk = desk;
      document.getElementById("menu").setAttribute("style", "display:none");
      
     const  objectEvent =  {
        currentDesk: desk,
        currentDeskPath: path,//title1/title2/ => to be display in the private component
        currentDeskPathId: pathId,//id1/id2/...  => to be used in dashboard.my-desk.tree to expand the currentDesk branch
        listOfDesk: this.headerModel.listOfDesk,
        emitter: this.componentFullName
      };

      this.stateService.emitSelectedCurrentDeskEvent(objectEvent);

      setTimeout(() => {
        document.getElementById("menu").removeAttribute("style");
      }, 500);
    }

  }

  /**
   * use to move the list of desk comboBox  on the scrool event, to overide the fixed behavior 
   */
  @HostListener('window:scroll', ['$event'])
  scrollHandler() {
    const v = -window.pageYOffset + 5;
    document.getElementById("nav").setAttribute("style", "top: " + v + "px !important");
  }

  ngOnDestroy(): void {
    this.subscription.forEach(elt => elt.unsubscribe());
  }

}
