import { Component, OnDestroy, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { HttpConfigService } from "@app/core/services/http/http-config.service";
import { UpdateAppService } from "@app/core/services/update-app/update-app.service";
import { Platform } from "@ionic/angular";
import { TranslocoService } from "@ngneat/transloco";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit, OnDestroy {
  title = "angularGUI";
  data = null;
  toggleLanguage = false;
  errorAccess = false;
  percentage = -1;
  download = null;
  constructor(
    private titleSignIn: Title,
    private updateAppService: UpdateAppService,
    public platform: Platform,
    private httpconfig: HttpConfigService,
    private transloco: TranslocoService
  ) {}

  ngOnInit() {
    this.titleSignIn.setTitle("koosseryFarm");
    this.httpconfig.ConfigReady.subscribe((val) => {
      if (val == true) {
        this.initApp();
      }
    });
  }

  checkUpdate() {
    if (this.platform.is("hybrid") && !this.httpconfig.fromStore) {
      this.updateAppService.percentage.subscribe((res) => {
        try {
          this.download = localStorage.getItem("download");
          this.percentage = res;
        } catch (error) {
          this.download = null;
        }
      });
    }
  }

  initApp() {
    let n = parseInt(localStorage.getItem("access_error"));
    if (n) {
      this.errorAccess = true;
    } else {
      const dataLang = JSON.parse(localStorage.getItem("defaultLang"));
      const sub = this.transloco
        .selectTranslation(dataLang.code?.split("-")[0])
        .subscribe(
          (tr) => {
            this.toggleLanguage = true;
            sub.unsubscribe();
            this.checkUpdate();
          },
          (err) => {
            localStorage.setItem("access_error", "1");
            this.errorAccess = true;
          }
        );
    }
  }

  reload() {
    location.reload();
  }

  ngOnDestroy(): void {}
}
