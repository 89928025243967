import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Platform } from "@ionic/angular";
import { HttpConfigService } from "@app/core/services/http/http-config.service";
@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.css"],
})
export class FooterComponent implements OnInit {
  constructor(
    public httpConfig: HttpConfigService,
    private router: Router,
    public platform: Platform
  ) {} //private transloco: TranslocoService,

  ngOnInit() {
  }

  homePage() {
    this.router.navigate(["/public/tools"]);
  }
}
