import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MessageType } from '@app/core/enum/message-type.enum';
import { DisplayErrorService } from '@app/core/services/error/display-error.service';
import { StateService as PrivateStateService} from '@app/private/services/state.service';
import { Subscription } from 'rxjs';


@Injectable()
export class SecurityService {
//configSecurity = 'assets/security.json';
configSecurity = "assets/security/security-";


public rolesConfig: any
public specificDesksConfig: any;
public specificFoldersConfig: any;
public specificDeskFoldersConfig: any;
public rootConfig: any;

subscription: Subscription;
 
constructor(private httpClient: HttpClient, private privateStateService: PrivateStateService, private displayErrorService: DisplayErrorService) {
  this.subscription = this.privateStateService.selectedCurrentDeskEvent.subscribe(
    (data) => {
      if(data){
              let company = data.currentDesk.company.id
              this.httpClient.get(this.configSecurity+company.toLowerCase()+".json").subscribe(
                (data) =>  {
                              this.specificDeskFoldersConfig = data['specificDeskFoldersConfig'];
                              this.specificFoldersConfig =  data['specificFoldersConfig'];
                              this.specificDesksConfig = data['specificDesksConfig'];
                              this.rolesConfig = data['rolesConfig'];
                              this.rootConfig = data['rootConfig'];
                },
                () => {
                  displayErrorService.openModalWithComponent({message:" Please configure the rights of the - "  + company + " -  company ", messageType:MessageType.WARNING})
                }
            );
    
      }  
   });
      
      
        
    
 } 
}