import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Company } from "@app/shared/models/user.model";
import * as $ from "jquery";
import { DisplayErrorService } from "../error/display-error.service";
import { MessageType } from "@app/core/enum/message-type.enum";
import { ToastController } from "@ionic/angular";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class HttpConfigService {
  configUrl = "assets/config.json";
  predictionParam;

  serverUrl: string;
  aiServer: string;
  mailServer: string;
  reportSenderMail: String;
  reportReceiversMail: string;
  mediaDir: string;
  port: string;
  defaultNav: string;
  updateDate: string;
  defaultCompany: Company;
  version: string;
  year: string;
  city: string;
  timezone: string;
  mediaDirCrops: string;
  fromStore: boolean;
  androidApkUrl: string;
  apkVersion: number;
  apkName: string = null;
  xmlUpdatePath: string;
  welcomeMessageSize: number;
  defaultLangCode: string;

  countryCode: string = null;
  selectedLang: any;
  langConfigPath;
  translationFolderPath;

  languages = [];
  countriesNationalLanguages;

  static availableLangs = [];
  predictionParamPath;

  ConfigReady = new BehaviorSubject(false);

  constructor(
    private httpClient: HttpClient,
    private displayErrorService: DisplayErrorService,
    public toastCtrl: ToastController
  ) {
    this.httpClient.get(this.configUrl).subscribe((data) => {
      this.serverUrl = data["serverUrl"];
      this.aiServer = data["aiServer"];
      this.mailServer = data["mailServer"];
      this.reportSenderMail = data["reportSenderMail"];
      this.reportReceiversMail = data["reportReceiversMail"];
      this.mediaDir = data["mediaDir"];
      this.mediaDirCrops = data["mediaDirCrops"];
      this.port = data["port"];
      this.defaultNav = data["defaultNav"];
      this.updateDate = data["updateDate"];
      this.defaultCompany = data["company"];
      this.version = data["version"];
      this.year = data["year"];
      this.fromStore = data["fromStore"];
      this.langConfigPath = data["langConfig"];
      this.translationFolderPath = data["translationFolder"];
      this.xmlUpdatePath = data["xmlUpdatePath"];
      this.welcomeMessageSize = data["welcomeMessageSize"];
      this.predictionParamPath = data["predictionParam"];

      sessionStorage.setItem("token", this.version);
      this.getApkData();
      this.getLangConfig();
    });
  }

  async openToast(msg: string) {
    const toast = await this.toastCtrl.create({
      message: msg,
      duration: 4000,
    });
    toast.present();
  }

  getLangConfig() {
    this.httpClient
      .get(this.langConfigPath, { responseType: "json" })
      .subscribe(
        (lang) => {
          let dataLang = lang["languages"];
          for (let k = 0; k < dataLang.length; k++) {
            if (dataLang[k].enable == true) {
              HttpConfigService.availableLangs.push(
                dataLang[k].code.split("-")[0]
              );
              this.languages.push(dataLang[k]);
            } else {
              try {
                if (
                  JSON.parse(localStorage.getItem("defaultLang")).code ==
                  dataLang[k].code
                ) {
                  localStorage.removeItem("defaultLang");
                }
              } catch (error) {
                console.log("========no default lang========");
                // this.getCountryCode();
              }
            }
          }

          this.defaultLangCode = lang["defaultLang"];
          this.countriesNationalLanguages = lang["countriesNationalLanguages"];
          localStorage.removeItem("access_error");

          let dl = "",
            dataL = "",
            code = "";
          try {
            dl = localStorage.getItem("defaultLang");
            dataL = JSON.parse(dl);
            code = dataL["code"];
          } catch (error) {
            localStorage.removeItem("defaultLang");
          }
          if (!dataL || dataL == undefined || code == undefined) {
            localStorage.removeItem("defaultLang");
            this.getCountryCode();
          } else {
            this.ConfigReady.next(true);
          }
        },
        (error) => {
          console.log("error while retreiving the language config file");
          console.log(error);
          let n = parseInt(localStorage.getItem("access_error"));

          localStorage.setItem("access_error", "1");

          this.displayErrorService.openModalWithComponent({
            message: error.message,
            messageType: MessageType.WARNING,
          });

          this.ConfigReady.next(true);
        }
      );
  }

  getCountryCode() {
    let result;
    this.httpClient.get("https://json.geoiplookup?").subscribe(
      (response) => {
        result = response["country_code"];
        this.countryCode = result.toLowerCase();
        this.getDefaultLang(result.toLowerCase());
      },
      (error) => {
        this.openToast("unable to retrieve the country");
        this.getDefaultLang();
      }
    );
  }

  getDefaultLang(countryCode?) {
    let nationalLanguage = null;
    let defaultLanguage = null;

    if (countryCode) {
      for (let key in this.countriesNationalLanguages) {
        if (key == countryCode) {
          nationalLanguage = this.countriesNationalLanguages[key];
        }
      }

      if (nationalLanguage != null) {
        defaultLanguage = this.languages.filter(
          (lang) => lang.code == nationalLanguage
        );
      }

      if (defaultLanguage?.length && defaultLanguage[0]?.enable == true) {
        localStorage.setItem("defaultLang", JSON.stringify(defaultLanguage[0]));
      }
    } else {
      defaultLanguage = this.languages.filter(
        (lang) => lang.code == this.defaultLangCode
      );
      if (defaultLanguage && defaultLanguage[0]?.code) {
        localStorage.setItem("defaultLang", JSON.stringify(defaultLanguage[0]));
      }
    }

    this.ConfigReady.next(true);
  }

  getApkData() {
    this.httpClient.get(this.xmlUpdatePath, { responseType: "text" }).subscribe(
      (data) => {
        this.androidApkUrl = data.split("<url>")[1].split("</url>")[0];
        this.apkVersion = parseInt(
          data.split("<version>")[1].split("</version>")[0]
        );
        this.apkName = this.androidApkUrl.split("/").pop();
        console.log(
          "url: " + this.androidApkUrl + " version: " + this.apkVersion,
          "apkName: " + this.apkName
        );
      },
      (error) => {}
    );
  }
}
