import { Component, OnInit } from "@angular/core";
import { MessageType } from "@app/core/enum/message-type.enum";
import { BsModalRef } from "ngx-bootstrap/modal";
import { UpdateAppService } from "@app/core/services/update-app/update-app.service";
import { DisplayErrorService } from "@app/core/services/error/display-error.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-message-box",
  templateUrl: "./message-box.component.html",
  styleUrls: ["./message-box.component.css"],
})
export class MessageBoxComponent implements OnInit {
  title: string;
  closeBtnName: string;
  updateBtnName: String;
  dowloadBtnName: String;

  message: string;
  messageType = MessageType.ERROR;

  versionNumber: any;
  versionCode: any;
  packageName: any;

  config = {
    animated: false,
    backdrop: "static",
  };

  constructor(
    public bsModalRef: BsModalRef,
    public updateAppService: UpdateAppService,
    private router: Router
  ) {}

  ngOnInit() {}

  update() {
    this.bsModalRef.hide();
    localStorage.setItem("download", "download");
    this.updateAppService.update();
  }

  download() {
    this.bsModalRef.hide();
    window.open(
      "https://koossery.com/farm/portal.TEST/#/public/download",
      "_system",
      "location=yes"
    );
  }

  close() {
    if (confirm("close koosseryFarm ? ")) {
      navigator["app"].exitApp();
    }
  }
}
