import { Component, ElementRef, Input, HostListener, OnInit, Output, ViewChild } from '@angular/core'
import { Subject } from 'rxjs'
import * as $ from 'jquery';

/*const loader = ['en', 'fr'].reduce((acc, lang) => {
  acc[lang] = () => import(`./translation/${lang}.json`);
  return acc;
}, {});*/


const randomMessages = [
  'Nice to meet you',
  'How are you?',
  'Not too bad, thanks',
  'What do you do?',
  'Is there anything else I can help you with?',
  'That\'s awesome',
  'Angular Elements is the bomb ðŸ’£ ',
  'Can you explain in more detail?',
  'Anyway I\'ve gotta go now',
  'It was a pleasure to chat with you',
  'We are happy to make you a custom offer!',
  'Bye',
  ':)',
]

const rand = max => Math.floor(Math.random() * max)

const getRandomMessage = () => randomMessages[rand(randomMessages.length)]


@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnInit {

  @ViewChild('bottom') bottom: ElementRef
  @ViewChild('message') msg: ElementRef

  public operator = {
    name: 'Operator',
    status: 'online',
    avatar: `https://randomuser.me/api/portraits/women/${rand(100)}.jpg`,
  }

  public client = {
    name: 'Guest User',
    status: 'online',
    avatar: `https://randomuser.me/api/portraits/men/${rand(100)}.jpg`,
  }

  public messages = []
  
  public focus = new Subject()
  
  constructor(){}

  ngOnInit() {
  }

  public addMessage(from, text, type: 'received' | 'sent') {
    this.messages.push({
      from,
      text,
      type,
      date: new Date().getTime(),
    })
	this.scrollToBottom()
  }

  public scrollToBottom() {
    if (this.bottom !== undefined) {
      this.bottom.nativeElement.scrollIntoView()
    }
  }

  public randomMessage() {
    this.addMessage(this.operator, getRandomMessage(), 'received')
  }

  public sendMessage(message) {
    if (message.trim() === '') {
      return
    }
    this.addMessage(this.client, message, 'sent')
    setTimeout(() => this.randomMessage(), 1000)
  }

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === '/') {
    }
  }

  public getMessage() {
    return this.msg.nativeElement.value
  }

  public clearMessage() {
    this.msg.nativeElement.value = ''
  }

  onSubmit() {
    const message = this.getMessage()
    if (message.trim() === '') {
      return
    }
	this.sendMessage(message)
    this.clearMessage()
  }
  
  displayChatMsg(){
	  $('.chat-widget').css('display','none');
	  $('.chat-div').css('display','block');
  }
  
  displayChatWidget(){
	  $('.chat-div').css('display','none');
	  $('.chat-widget').css('display','block');
  }

}
