import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { MessageType } from "@app/core/enum/message-type.enum";
import { MessageBoxComponent } from "@app/shared/components/message-box/message-box.component";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";

@Injectable({
  providedIn: "root",
})
export class DisplayErrorService {
  bsModalRef: BsModalRef;

  constructor(private modalService: BsModalService, private router: Router) {}

  openModalWithComponent(
    error: { message: string; messageType: MessageType },
    nameButton?
  ) {
    let initialState = {
      message: error.message,
      messageType: error.messageType,
      title: error.messageType,
    };

    this.bsModalRef = this.modalService.show(MessageBoxComponent, {
      initialState,
      //animated: true,
      //keyboard: false,
      backdrop: true, // 'static'
      ignoreBackdropClick: false,
    });

    if (nameButton) {
      this.bsModalRef.content.downloadBtnName = nameButton;
    } else {
      if (
        error.messageType == MessageType.WARNING ||
        error.messageType == MessageType.ERROR ||
        error.messageType == MessageType.INFO
      ) {
        this.bsModalRef.content.closeBtnName = "OK"; // app.core.closeBtnName
      } else {
        this.bsModalRef.content.updateBtnName = "Update"; //app.core.updateBtnName
        this.bsModalRef.content.cancelBtnName = "No thanks"; //;app.core.cancelBtnName
      }
    }
  }
}
