import { NgModule } from '@angular/core';

import { SubscriptionRoutingModule } from './subscription-routing.module';
import { SubscriptionComponent } from './subscription.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { SharedModule } from '@app/shared/shared.module';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';


@NgModule({
  declarations: [SubscriptionComponent, SignUpComponent, ForgotPasswordComponent],
  imports: [
    SharedModule,
    SubscriptionRoutingModule
  ]
})
export class SubscriptionModule { }
