import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class UserLanguageInterceptor implements HttpInterceptor {
  defaultLang = "en";
  constructor() {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    try {
      if(!request.url.includes(".json"))
      request = request.clone({
        setHeaders: {
          "Content-Language": localStorage.getItem("defaultLang")
            ? (this.defaultLang = JSON.parse(
                localStorage.getItem("defaultLang")
              )?.code?.split("-")[0])
            : "en",
        },
      });
    } catch (error) {
      request = request.clone({
        setHeaders: {
          "Content-Language": "en",
        },
      });
    }

    return next.handle(request);
  }
}
