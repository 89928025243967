import {
  AfterContentChecked,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from "@angular/core";
import { Router } from "@angular/router";
import { HttpConfigService } from "@app/core/services/http/http-config.service";
import { TranslocoService } from "@ngneat/transloco";
import { Platform } from "@ionic/angular";

@Component({
  selector: "app-select-crop",
  templateUrl: "./select-crop.component.html",
  styleUrls: ["./select-crop.component.css"],
})
export class SelectCropComponent
  implements OnInit, AfterContentChecked, OnChanges {
  @Input() crops = null;
  @Input() position = { right: 0, top: 0 };
  @Input() selectedCrop = null;
  @Output() updateSelectedCropEvent = new EventEmitter();
  searchCrop: any;
  filteredListCrops: any = [];

  default = null;
  toggleLanguage = false;

  constructor(public platform: Platform) {}

  ngOnInit(): void {}

  ngAfterContentChecked() {}

  filterDropdown(e) {
    let searchString = e.toLowerCase();
    if (!searchString) {
      this.filteredListCrops = this.crops.slice();
      return;
    } else {
      this.filteredListCrops = this.crops.filter(
        (user) => user.name.toLowerCase().indexOf(searchString) > -1
      );
    }
  }

  selectCrop(crop) {
    if (crop.name != this.selectedCrop?.name) {
      this.selectedCrop = crop;
      this.default = crop.id;
      this.updateSelectedCropEvent.emit(crop);
    }
  }
  ngOnChanges() {
    if (this.crops != undefined) {
      this.filteredListCrops = this.crops;
      this.toggleLanguage = true;
    }
  }
}
