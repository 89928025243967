import { NgModule, Optional, SkipSelf } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HttpClient, HttpClientModule } from "@angular/common/http";

import { PermissionGuard } from "./guards/permission.guard";
import { PublicModule } from "@app/public/public.module";
import { PrivateModule } from "@app/private/private.module";
import { httpInterceptorProviders } from "./interceptors";
import { NgxPermissionsModule } from "ngx-permissions";
import { ChatComponent } from "./components/chat/chat.component";
import { TRANSLOCO_SCOPE, TranslocoModule } from "@ngneat/transloco";
//import { File } from '@ionic-native/file/ngx';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';


@NgModule({
  declarations: [ChatComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    PublicModule,
    PrivateModule,
    NgxPermissionsModule.forChild(),
    TranslocoModule,
  ],
  providers: [
    PermissionGuard,
    //File,
    FileOpener,
    httpInterceptorProviders,
  ],
  exports: [ChatComponent],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error("CoreModule is already loaded.");
    }
  }
}
