import { HttpClient, HttpEvent } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { HttpConfigService } from "@app/core/services/http/http-config.service";

@Injectable()
export class PublicFacadeService {
  //servicePath = 'koosserydesk.ai.python/service/tool/'
  getCropDictService = "koosserydesk.ai.python/service/crop/getCropDictionary";

  constructor(
    private httpConfig: HttpConfigService,
    private http: HttpClient
  ) {}

  /*retreiveCrops() {
    return this.http.get<any>(
      this.httpConfig?.serverUrl.concat(
        "/" + this.httpConfig?.aiServer + "/" + this.getCropDictService
      )
    );
  }*/

  retreiveCrops() {
    return this.http.get<any>(
      this.httpConfig?.aiServer + "/" + this.getCropDictService
    );
  }
}
