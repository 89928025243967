import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { DisplayLoaderComponent } from "./components/display-loader/display-loader.component";
import { NgxPermissionsModule } from "ngx-permissions";
import { NgWizardModule, NgWizardConfig, THEME } from "ng-wizard";
import { MessageBoxComponent } from "./components/message-box/message-box.component";
import { GoogleChartsModule } from "angular-google-charts";
import { NgxPaginationModule } from "ngx-pagination";
import { InputDataComponent } from "./components/input-data/input-data.component";
import { FilterItemsPipe } from "./pipes/filter-items.pipe";
import { HighlightTextDirective } from "./directives/highlight-text.directive";
import { DragDropDirective } from "./directives/drag-drop.directive";
import { TRANSLOCO_SCOPE, TranslocoModule } from "@ngneat/transloco";
import { TranslocoRootModule } from "src/app/transloco-root.module";
import { LanguagesFormComponent } from "./components/languages-form/languages-form.component";
import { IonicModule } from "@ionic/angular";
import { SelectCropComponent } from "./components/select-crop/select-crop.component";

const ngWizardConfig: NgWizardConfig = {
  theme: THEME.default,
};

@NgModule({
  declarations: [
    MessageBoxComponent,
    DisplayLoaderComponent,
    InputDataComponent,
    FilterItemsPipe,
    HighlightTextDirective,
    DragDropDirective,
    LanguagesFormComponent,
    SelectCropComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    GoogleChartsModule,
    NgWizardModule.forRoot(ngWizardConfig),
    IonicModule.forRoot(),
    NgxPaginationModule,
    ReactiveFormsModule,
    TranslocoRootModule,
    TranslocoModule,
  ],
  exports: [
    CommonModule,
    GoogleChartsModule,
    NgWizardModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    DisplayLoaderComponent,
    NgxPermissionsModule,
    NgxPaginationModule,
    InputDataComponent,
    TranslocoRootModule,
    TranslocoModule,
    LanguagesFormComponent,
    SelectCropComponent,
  ],
  bootstrap: [MessageBoxComponent],
})
export class SharedModule {}
