import { HttpClient } from "@angular/common/http";
import {
  TRANSLOCO_LOADER,
  Translation,
  TranslocoLoader,
  TRANSLOCO_CONFIG,
  translocoConfig,
  TranslocoModule,
} from "@ngneat/transloco";
import { Injectable, NgModule } from "@angular/core";
import { environment } from "../environments/environment";
import { StateService } from "@app/public/services/state.service";
import { HttpConfigService } from "@app/core/services/http/http-config.service";

@Injectable({ providedIn: "root" })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(
    private http: HttpClient,
    public stateService: StateService,
    private httpConfigService: HttpConfigService
  ) {}

  getTranslation(lang: string) {
    let path = this.httpConfigService?.translationFolderPath;
    return this.http.get<Translation>(`${path}${lang}.json`);
  }
}

@NgModule({
  exports: [TranslocoModule],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        availableLangs: HttpConfigService.availableLangs,
        reRenderOnLangChange: true,
        prodMode: environment.production,
      }),
      deps:[HttpConfigService]
    },
    { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader },
  ],
})
export class TranslocoRootModule {}
