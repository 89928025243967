import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from "@angular/common/http";
import { EMPTY, Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { MessageType } from "../enum/message-type.enum";
import { Market } from "@ionic-native/market";
import { Router } from "@angular/router";
import { DisplayErrorService } from "../services/error/display-error.service";
import { NEVER } from "rxjs";
/**
 * Adds a default alert handler to all requests.
 */
@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(
    private displayErrorService: DisplayErrorService,
    private router: Router
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next
      .handle(request)
      .pipe(catchError((error) => this.handleError(error, next, request)));
  }

  private handleError(
    error: HttpErrorResponse,
    next,
    request
  ): Observable<HttpEvent<any>> {
    console.log(error);
    let custumError: {
      message: any;
      messageID: any;
      messageType: any;
    } = { message: "", messageID: "", messageType: "" }; //messageID: "",

    if (!navigator.onLine) {
      custumError.message = "check your computer's network connection"; //app.core.errorConnexionIssue
      custumError.messageType = MessageType.WARNING;
      return throwError(custumError);
    } else if (error.status === 0) {
      custumError.message =
        "Sorry the server is not reachable. Please try again later!"; //app.core.errorServerNoReachable
      custumError.messageType = MessageType.WARNING;
      return throwError(custumError);
    } else if (error instanceof DOMException) {
      custumError.message = error;
      custumError.messageType = MessageType.ERROR;
      return throwError(custumError);
    } else if (error.status == 500 || error.error instanceof ErrorEvent) {
      custumError.message = error.error.message;
      custumError.messageID = error.error.messageID;
      if (error.error.messageID == "KOOSSERYDESKAIPYTHONFILTER-001") {
        //here should be the messageID of the version exception
        custumError.messageType = MessageType.ALERT;
      } else {
        custumError.messageType = MessageType.ERROR;
      }
      return throwError(custumError);
    }
    custumError.message = "Sorry an error occured. Please try again later !"; //app.core.errorUnknown
    custumError.messageType = MessageType.WARNING;
    return throwError(custumError);
  }
}
